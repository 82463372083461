import React, { useState, useCallback } from 'react'
import { useFormState } from 'react-final-form';

import { SelectInput,AutocompleteInput, TextInput, ReferenceInput, SimpleForm, required, minLength, maxLength, minValue, maxValue, choices, NumberInput, BooleanInput} from 'react-admin';
import { RaGrid } from 'ra-compact-ui';
import { Box } from '@material-ui/core';
import PatientQuickCreateButton from './PatientQuickCreateButton.js';

import { years, SEX_CHOICES, PHOTOTYPE_CHOICES, BMI_CHOICES } from './helpers';


var currentYear = new Date().getFullYear();


const validateSex = choices([
  'm','f',
], 'Please select a sex');





export default ({updateForm}) => {
  const [version, setVersion] = useState(0);
  const handleChange = useCallback(() => setVersion(version + 1), [version]);

  return (
    
    <div>

          <SimpleForm toolbar={false} onSubmit={(event) => event.preventDefault()}>
          <RaGrid container spacing={1} style={{ width: "100%" }} >
                <RaGrid item xs={4}>
                  <ReferenceInput key={version} label="Existing Patient" source="patient" reference="patients" resettable perPage={1000} fullWidth sort={{ field: 'id', order: 'DESC' }} 
                                        onChange={(event, key, payload) => {updateForm('patient', event.target.value) }} 
                                        >
                    <SelectInput helperText="Choose an existing patient"  validate={[required()]} optionValue="id"  optionText={(p)=> 'Patient #'+p.id+ ' ('+p.birthday_year+')'} 
                      />
                  </ReferenceInput>
                </RaGrid>
                <RaGrid item xs={2} align="center">
                  <br/><strong>or</strong>
                </RaGrid>
                <RaGrid item xs={4}>
                  <PatientQuickCreateButton onChange={handleChange} updateForm={updateForm} />
                </RaGrid>
                <RaGrid item xs={12} ><br/></RaGrid>
                <RaGrid item xs={6}>
                <NumberInput source="age" 
                    validate={[minValue(1), maxValue(120)]}
                    min={0} max={120} step={1}
                    label="Age"
                    inputProps={{readOnly: true}}
                    onChange={(event, key, payload) => {
                                          updateForm('age', event.target.value)
                                      }}/>
                  <div>
                    <SelectInput source="phototype" 
                    validate={[required()]}
                    choices={PHOTOTYPE_CHOICES}
                                        optionValue="id"
                                        optionText="text"
                                        onChange={(event, key, payload) => {
                                          updateForm('phototype', event.target.value)
                                      }}
                                      />
                    </div>



                  <BooleanInput source="personal_history_of_mm" 
                    label="Personal history of MM"
                    onChange={(value, event) => {
                      updateForm('personal_history_of_mm', value)
                  }}/>
                  <BooleanInput source="family_history_of_mm" 
                    label="Family history of MM (1st degree relative)"
                    onChange={(value, event) => {
                      updateForm('family_history_of_mm', value)
                  }}/>
                  <BooleanInput source="pheomelanine"
                    label="Pheomelanine"
                    onChange={(value, event) => {
                      updateForm('pheomelanine', value)
                  }}/>
                  <BooleanInput source="blond_hair" 
                    label="Blond hair"
                    onChange={(value, event) => {
                      updateForm('blond_hair', value)
                  }}/>

                </RaGrid>
                <RaGrid item xs={6}>
                <div>
                  <SelectInput source="sex" 
                    choices={SEX_CHOICES}
                    validate={[required(), validateSex]}
                    optionValue="id"
                    optionText="text"
                    onChange={(event, key, payload) => {
                      updateForm('sex', event.target.value)
                  }}
                  />
                  </div>
                  
                  <SelectInput source="bmi" 
                    label="BMI"
                    choices={BMI_CHOICES}
                                        optionValue="id"
                                        optionText="text"
                                        onChange={(event, key, payload) => {
                                          updateForm('bmi', event.target.value)
                                      }}
                                      />

                    
                  <BooleanInput source="green_light_blue_blue_eyes" 
                    label="Green / light-blue / blue eyes"
                    onChange={(value, event) => {
                      updateForm('green_light_blue_blue_eyes', value)
                    }}/>
                  <BooleanInput source="nevi_right_arm" 
                    label="Nevi on the right arm ≥ 11 lesions"
                    onChange={(value, event) => {
                      updateForm('nevi_right_arm', value)
                  }}/>
                  <BooleanInput source="pos_history_sunburns_childhood" 
                    label="Pos history sunburns childhood (< 14 years)"
                    onChange={(value, event) => {
                      updateForm('pos_history_sunburns_childhood', value)
                  }}/>
                  <BooleanInput source="red_wine_intake" 
                    label="Red wine intake (2 glasses/day)"
                    onChange={(value, event) => {
                      updateForm('red_wine_intake', value)
                  }}/>
                  <BooleanInput source="smoke" 
                     label="Smoke (> 5 years consecutively)"
                     onChange={(value, event) => {
                      updateForm('smoke', value)
                  }}/>
                </RaGrid>
                <RaGrid item xs={12}>
                <Box sx={{ fontStyle: 'italic' }}>
                  Ref: Tognetti et al., Dermatol. Pract. Concept., 2018 Tognetti et. al, JEADV, 2018, Tognetti et al, Ach. Dermatol. Res., 2021, Roffman et al., Sci. Rep., 2018
                </Box>
                </RaGrid>
            </RaGrid>
            </SimpleForm>
    </div>

  )
}