import React, {useState} from "react";

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import Checkbox from '@material-ui/core/Checkbox';


import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import { extract_position } from "./helpers";

function Rear({height, width, selected, setSelected, selectedLesion, setSelectedLesion, exams, mode}) {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const selectedColor = '#FFFF00';
  const lesionsColor = '#FFA500';

  let lesionsBySite = exams.reduce(function (r, a) {
   r[a.lesion.site] = r[a.lesion.site] || [];
   r[a.lesion.site].push(a);
   return r;
}, Object.create(null));
  
  function getFillBySite(site) {
   if (selectedLesion && selectedLesion?.site === site) return {fill: lesionsColor, fillOpacity: 1};
   if (site in lesionsBySite) {
      return {fill: selected === site ? selectedColor : lesionsColor, fillOpacity: selected === site ? 1 : 0.3};
   }
   else return selected === site ? {fill: selectedColor, fillOpacity: 1} : {fill: ''};
  }

  function getMacroByExam(exam) {
   try {
      return exam.images.filter((img => img.device === null))[0];
   } catch {
      return ''
   }
  }

  function handleClick(e) {
     //console.log(extract_position(e.currentTarget.id), lesionsBySite);
   if (extract_position(e.currentTarget.id) in lesionsBySite) {
     // setSelected(null) 
      setOpen(extract_position(e.currentTarget.id));
   } else {
      setSelectedLesion(null);
      if (selected===extract_position(e.currentTarget.id)) setSelected(null) 
      else setSelected(extract_position(e.currentTarget.id));
   }
  }

  const handleClose = () => {
   setOpen(false);
 };

 const handleListItemClick = (lesion) => {
    console.log(lesion, typeof lesion)
   if (typeof lesion !== 'string') {
      console.log('1a')
      setSelectedLesion(lesion);
      //setSelected(null);
      console.log('1b')
   } else {
      console.log('2a')
      setSelected(lesion);
      //setSelectedLesion(null);
      console.log('2b')
   }
   setOpen(false);
 };

  //console.log(selected, selectedLesion)

  return ( 
    <>
     <Dialog
        fullWidth={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          Choose a lesion already inserted{!mode && ' or Create a new one'}
        </DialogTitle>
        <List>
        {exams.filter((e) => e.lesion.site===open).map((e) => (
          <ListItem button selected={e.lesion.id===selectedLesion?.id} onClick={() => handleListItemClick(mode ? e.id : e.lesion)} key={e.lesion.id}>
            <ListItemAvatar>
              <img width="300" src={getMacroByExam(e)?.file} />
            </ListItemAvatar>
            <ListItemText id={e.lesion.id} style={{padding: 10}} primary={'Lesion # '+e.lesion.id + (getMacroByExam(e) && ' ('+new Date(getMacroByExam(e)?.created_at).toLocaleDateString()+')')} secondary={e.lesion.site}/>
            <ListItemSecondaryAction>
              <Checkbox
                edge="end"
                checked={e.lesion.id===selectedLesion?.id}
                inputProps={{ 'aria-labelledby': e.lesion.id }}
              />
            </ListItemSecondaryAction>
          </ListItem>
        ))}

{!mode &&
        <ListItem autoFocus button onClick={() => handleListItemClick(open)}>
        <ListItemAvatar>
            </ListItemAvatar>
          <ListItemText primary="Create new lesion" secondary={open} />
        </ListItem>
}
      </List>
      </Dialog>

  <svg
   xmlns="http://www.w3.org/2000/svg"
   height={height}
   width={width}
   id="Rear"
   viewBox={"0 0 "+height+" "+width}
   y="0px"
   x="0px"
   version="1.1" 
   style={{cursor: 'pointer', fill: "#009FE5", stroke: '#FFFFFF', transform: 'scale(1.8)', marginTop: 150,
   strokeWidth: 2.8346, strokeMiterlimit:10, ":hover": {
    background: "#efefef"
  }}}>

<g onClick={handleClick} 
   transform="translate(-748.26356,-600.06701)"
   id="RHead" style={getFillBySite('Rear Head')}>
	<path
   className="st0"
   d="m 1001.6,334.5 c -0.2,-0.4 -1,-2.4 -2.6,-2.6 -1.4,-0.2 -2.9,1.1 -3.9,3.1 0.2,-2 0.5,-4.9 0.5,-8.4 0.1,-6.5 0.1,-12.1 -2,-18.6 -2.8,-8.5 -7.7,-13.9 -9,-15.2 -4.9,-5.1 -9.8,-7.5 -11.2,-8.1 -6.5,-3 -12.3,-3.3 -15.5,-3.2 -3.2,0 -9.1,0.3 -15.5,3.2 -1.4,0.6 -6.4,3 -11.2,8.1 -1.3,1.4 -6.2,6.7 -9,15.2 -2.1,6.6 -2.1,12.1 -2,18.6 0,3.5 0.3,6.3 0.5,8.4 -0.9,-2.1 -2.5,-3.3 -3.9,-3.1 -1.6,0.2 -2.5,2.2 -2.6,2.6 -1.7,3.9 2.1,6.2 3.5,13.9 0.9,4.7 0.1,7.7 2.5,9.6 0.3,0.2 1.8,1.4 2.9,0.9 0.9,-0.5 1.2,-2 0.6,-3.9 0.5,1.9 1.1,3.8 1.6,5.8 3.8,2.6 9.6,6 17.2,8.2 6,1.7 11.4,2.2 15.4,2.2 4,0 9.4,-0.5 15.4,-2.2 7.7,-2.2 13.5,-5.6 17.2,-8.2 0.5,-1.9 1.1,-3.8 1.6,-5.8 -0.5,1.8 -0.2,3.4 0.6,3.9 1,0.6 2.6,-0.7 2.9,-0.9 2.4,-1.9 1.6,-4.9 2.5,-9.6 1.4,-7.7 5.2,-10 3.5,-13.9 z"
   id="path78" />
</g>
<g onClick={handleClick} 
   transform="translate(-748.26356,-600.06701)"
   id="RNeck" style={getFillBySite('Rear Neck')}>
	<path
   class="st0"
   d="m 957.8,371.2 c -4,0 -9.4,-0.5 -15.4,-2.2 -7.7,-2.2 -13.5,-5.6 -17.2,-8.2 0.4,1.1 0.9,2.9 1.5,5 0.6,2.5 1.4,5.6 2.5,14.8 1,7.9 1,9.8 2.5,13.2 0,0 1.3,3 3,5.2 6.4,8.6 21,8.8 23.2,8.8 0.1,0 0.2,0 0.2,0 10.9,0 19.6,-4.3 22.9,-8.7 1.8,-2.4 3,-5.2 3,-5.2 0,0 0.4,-1 0.8,-2.1 1.2,-3.8 1.5,-8.5 1.7,-11.1 0.5,-6.6 0.8,-10.3 2.5,-14.8 1,-2.6 2.1,-4.5 1.5,-5 -0.7,-0.6 -3.1,1.9 -7.1,4.3 -3.6,2.1 -6.5,2.9 -10.1,4 -2.9,0.8 -8.3,2.2 -15.2,2.2 -0.2,-0.2 -0.2,-0.2 -0.3,-0.2 z"
   id="path81" />
</g>
<g onClick={handleClick} 
   transform="translate(-748.26356,-600.06701)"
   id="RLShoulder" style={getFillBySite('Rear Left Shoulder')}>
	<path
   class="st0"
   d="m 957.8,407.7 c -2.4,0.1 -15.8,0.6 -23.2,-8.8 -1,-1.3 -2.1,-3 -3,-5.2 -1.1,1.3 -2.8,3.2 -5,5.2 -7.3,7 -14.3,11.1 -19.2,13.8 -11.7,6.3 -14.6,5.8 -31.2,13 -13.8,6 -20.8,9 -26,14 -9.8,9.3 -13.8,20.7 -15,24 -1.8,5.1 -2.6,9.5 -3,12.8 5.1,0.4 9.3,0.5 12,0.5 9.1,0 13.1,-0.9 19.2,0.5 3.9,0.9 6.9,2.3 8.8,3.2 1.5,-6.6 4.8,-15.8 12.2,-23.9 2.3,-2.5 6,-5.9 28,-16.6 17.5,-8.6 21.4,-17.7 34.2,-19 4.7,-0.5 8.7,0 11.2,0.5 0,-4.6 0,-9.3 0,-14 z"
   id="path84" />
</g>
<g onClick={handleClick} 
   transform="translate(-748.26356,-600.06701)"
   id="RLArm" style={getFillBySite('Rear Left Arm')}>
	<path
   class="st0"
   d="m 832.1,476.5 c 5.1,0.4 9.3,0.5 12,0.5 9.1,0 13.1,-0.9 19.2,0.5 3.9,0.9 6.9,2.3 8.8,3.2 -0.1,3.3 -0.1,8.4 0.5,14.5 1.1,10.3 2.9,12.9 4,21 1.7,12.2 -0.4,22.1 -1.5,27.5 -2.1,9.9 -4.3,11.4 -7.5,22 -2,6.7 -4.4,16.6 -5,29.5 -0.8,-2.4 -2.9,-7.5 -8,-11.7 -4.4,-3.7 -8.9,-4.8 -11,-5.3 -7.1,-1.7 -13.1,-0.4 -16,0.5 -6.9,2.1 -11.2,6.1 -13.2,8.2 0.2,-2.3 0.6,-5.7 1.7,-9.7 2.1,-8.1 5.5,-12.2 7.5,-17 3.3,-8.1 2.9,-12 5,-25.5 1.1,-7 2.9,-17 6,-29 -1.2,-4 -2.4,-9.1 -2.8,-15.2 -0.4,-5.4 -0.1,-10.2 0.3,-14 z"
   id="path87" />
</g>
<g onClick={handleClick} 
   transform="translate(-748.26356,-600.06701)"
   id="RLForearm" style={getFillBySite('Rear Left Forearm')}>
	<path
   class="st0"
   d="m 862.6,595.2 c -0.8,-2.4 -2.9,-7.5 -8,-11.7 -4.4,-3.7 -8.9,-4.8 -11,-5.3 -7.1,-1.7 -13.1,-0.4 -16,0.5 -6.9,2.1 -11.2,6.1 -13.2,8.2 -1.4,4.1 -3.4,10.8 -4.8,19.2 -1.5,8.9 -1.1,12.2 -2.7,28.3 -1.1,11.8 -1.7,17.7 -2.7,22 -1.9,8.3 -4,12.7 -7,25.7 -0.9,3.9 -1.6,7.2 -2,9.3 0.9,1.6 2.8,4.2 6,6.3 3.3,2.1 6.3,2.6 8.7,3 4.7,0.7 8.8,0.1 11.7,-0.7 1.3,-1.8 3.1,-4.8 4.3,-8.7 1.1,-3.6 1.2,-6.4 1.3,-7.7 0.2,-3.1 1,-5.9 8,-21 3.7,-8 6.1,-13.1 10.3,-21.3 5.9,-11.4 6.5,-11.7 8.7,-16.3 3.2,-6.4 6.8,-16.2 8.4,-29.8 z"
   id="path90" />
</g>
<g onClick={handleClick} 
   transform="translate(-748.26356,-600.06701)"
   id="RLHand" style={getFillBySite('Rear Left Hand')}>
	<path
   class="st0"
   d="m 784.6,696.1 c -4.7,3 -7.8,6.4 -12.2,11.4 -5.9,6.6 -6.6,8.8 -10.4,11 -4.4,2.5 -6.5,3.7 -8.7,3.6 -0.5,0 -1.9,-0.2 -2.8,0.7 -0.9,0.8 -0.8,2.2 -0.8,2.5 0.2,2.8 3.3,4.7 3.8,4.9 3.8,2.2 8.2,0.7 10,0.2 2.3,-0.7 3.9,-1.8 5.5,-2.9 3.2,-2.2 5.3,-4.5 6.7,-6.2 0.2,2 0.4,5 -0.2,8.5 -0.6,3.2 -1.8,6.7 -10.6,20.2 0,0 -4,6.2 -7.5,15.8 -0.4,1.1 -1.2,3.3 -0.1,5.2 0.9,1.6 2.9,2.8 4.7,2.5 2,-0.3 3,-2.4 4.8,-5.8 1.8,-3.5 3.9,-6.8 5.5,-10.4 4.1,-9.4 10.6,-17.7 11.6,-17.2 0.9,0.4 -1.7,8.2 -4.2,13.9 -0.7,1.7 -3,7.5 -7.5,19.1 -0.3,0.7 -0.9,2.5 -0.4,4.4 0.2,0.6 0.6,2 1.9,2.8 0.2,0.1 1.6,1 3.1,0.5 1.1,-0.3 1.7,-1.1 2,-1.5 5.6,-7.5 7,-14.2 7,-14.2 2.5,-12.1 8.1,-23.3 9.4,-23 1.1,0.2 0.4,8.6 -0.2,13.6 -0.9,6.4 -0.9,12.9 -1.3,19.3 0,0.4 -0.1,1.3 0.4,2.2 0.5,1 1.6,1.8 2.4,1.8 2.9,0.2 5.8,-8.6 6.2,-9.9 1,-3.2 1.6,-6 2.6,-14.3 1.2,-10 1.4,-14.7 2,-14.7 0.7,0 1.3,5.7 1.3,6.3 1.2,12 -1.7,17.7 1.7,20.7 1.3,1.1 3.4,1.7 4.7,1 1.7,-0.9 1.7,-4 1.7,-5.3 -0.1,-9.8 0.3,-23.9 1,-30.7 0.6,-6.3 1.4,-5.5 2.7,-15.3 0.9,-7 1.2,-12.8 1.3,-16.7 -2.9,0.8 -7,1.4 -11.7,0.7 -2.3,-0.4 -5.4,-0.9 -8.7,-3 -3.2,-2.1 -5.1,-4.8 -6,-6.3 -2.7,0.6 -6.5,2 -10.7,4.6 z"
   id="path93" />
</g>
<g onClick={handleClick} 
   transform="translate(-748.26356,-600.06701)"
   id="RRShoulder" style={getFillBySite('Rear Right Shoulder')}>
	<path
   class="st0"
   d="m 957.8,407.7 c 2.4,0.1 15.8,0.6 23.2,-8.8 1,-1.3 2.1,-3 3,-5.2 1.1,1.3 2.8,3.2 5,5.2 7.3,7 14.3,11.1 19.2,13.8 11.7,6.3 14.6,5.8 31.3,13 13.8,6 20.8,9 26,14 9.8,9.3 13.8,20.7 15,24 1.8,5.1 2.6,9.5 3,12.8 -5.1,0.4 -9.3,0.5 -12,0.5 -9.1,0 -13.1,-0.9 -19.2,0.5 -3.9,0.9 -6.9,2.3 -8.8,3.2 -1.5,-6.6 -4.8,-15.8 -12.2,-23.9 -2.3,-2.5 -6,-5.9 -28,-16.6 -17.5,-8.6 -21.4,-17.7 -34.2,-19 -4.7,-0.5 -8.7,0 -11.2,0.5 -0.1,-4.6 -0.1,-9.3 -0.1,-14 z"
   id="path96" />
</g>
<g onClick={handleClick} 
   transform="translate(-748.26356,-600.06701)"
   id="RRArm" style={getFillBySite('Rear Right Arm')}>
	<path
   class="st0"
   d="m 1083.5,476.5 c -5.1,0.4 -9.3,0.5 -12,0.5 -9.1,0 -13.1,-0.9 -19.2,0.5 -3.9,0.9 -6.9,2.3 -8.8,3.2 0.1,3.3 0.1,8.4 -0.5,14.5 -1.1,10.3 -2.9,12.9 -4,21 -1.7,12.2 0.4,22.1 1.5,27.5 2.1,9.9 4.3,11.4 7.5,22 2,6.7 4.4,16.6 5,29.5 0.8,-2.4 2.9,-7.5 8,-11.7 4.4,-3.7 8.9,-4.8 11,-5.3 7.1,-1.7 13.1,-0.4 16,0.5 6.9,2.1 11.2,6.1 13.2,8.2 -0.2,-2.3 -0.6,-5.7 -1.7,-9.7 -2.1,-8.1 -5.5,-12.2 -7.5,-17 -3.3,-8.1 -2.9,-12 -5,-25.5 -1.1,-7 -2.9,-17 -6,-29 1.2,-4 2.4,-9.1 2.8,-15.2 0.4,-5.4 0.2,-10.2 -0.3,-14 z"
   id="path99" />
</g>
<g onClick={handleClick} 
   transform="translate(-748.26356,-600.06701)"
   id="RRForearm" style={getFillBySite('Rear Right Forearm')}>
	<path
   class="st0"
   d="m 1053,595.2 c 0.8,-2.4 2.9,-7.5 8,-11.7 4.4,-3.7 8.9,-4.8 11,-5.3 7.1,-1.7 13.1,-0.4 16,0.5 6.9,2.1 11.2,6.1 13.2,8.2 1.4,4.1 3.4,10.8 4.8,19.2 1.5,8.9 1.1,12.2 2.7,28.3 1.1,11.8 1.7,17.7 2.7,22 1.9,8.3 4,12.7 7,25.7 0.9,3.9 1.6,7.2 2,9.3 -0.9,1.6 -2.8,4.2 -6,6.3 -3.3,2.1 -6.3,2.6 -8.7,3 -4.7,0.7 -8.8,0.1 -11.7,-0.7 -1.3,-1.8 -3.1,-4.8 -4.3,-8.7 -1.1,-3.6 -1.2,-6.4 -1.3,-7.7 -0.2,-3.1 -1,-5.9 -8,-21 -3.7,-8 -6.1,-13.1 -10.3,-21.3 -5.9,-11.4 -6.5,-11.7 -8.7,-16.3 -3.1,-6.4 -6.8,-16.2 -8.4,-29.8 z"
   id="path102" />
</g>
<g onClick={handleClick} 
   transform="translate(-748.26356,-600.06701)"
   id="RRHand" style={getFillBySite('Rear Right Hand')}>
	<path
   class="st0"
   d="m 1131,696.1 c 4.7,3 7.8,6.4 12.2,11.4 5.9,6.6 6.6,8.8 10.4,11 4.4,2.5 6.5,3.7 8.7,3.6 0.5,0 1.9,-0.2 2.8,0.7 0.9,0.8 0.8,2.2 0.8,2.5 -0.2,2.8 -3.3,4.7 -3.8,4.9 -3.8,2.2 -8.2,0.7 -10,0.2 -2.3,-0.7 -3.9,-1.8 -5.5,-2.9 -3.2,-2.2 -5.3,-4.5 -6.7,-6.2 -0.2,2 -0.4,5 0.2,8.5 0.6,3.2 1.8,6.7 10.6,20.2 0,0 4,6.2 7.5,15.8 0.4,1.1 1.2,3.3 0.1,5.2 -0.9,1.6 -2.9,2.8 -4.7,2.5 -2,-0.3 -3,-2.4 -4.8,-5.8 -1.8,-3.5 -3.9,-6.8 -5.5,-10.4 -4.1,-9.4 -10.6,-17.7 -11.6,-17.2 -0.9,0.4 1.7,8.2 4.2,13.9 0.7,1.7 3,7.5 7.5,19.1 0.3,0.7 0.9,2.5 0.4,4.4 -0.2,0.6 -0.6,2 -1.9,2.8 -0.2,0.1 -1.6,1 -3.1,0.5 -1.1,-0.3 -1.7,-1.1 -2,-1.5 -5.6,-7.5 -7,-14.2 -7,-14.2 -2.5,-12.1 -8.1,-23.3 -9.4,-23 -1.1,0.2 -0.4,8.6 0.2,13.6 0.9,6.4 0.9,12.9 1.3,19.3 0,0.4 0.1,1.3 -0.4,2.2 -0.5,1 -1.6,1.8 -2.4,1.8 -2.9,0.2 -5.8,-8.6 -6.2,-9.9 -1,-3.2 -1.6,-6 -2.6,-14.3 -1.2,-10 -1.4,-14.7 -2,-14.7 -0.7,0 -1.3,5.7 -1.3,6.3 -1.2,12 1.7,17.7 -1.7,20.7 -1.3,1.1 -3.4,1.7 -4.7,1 -1.7,-0.9 -1.7,-4 -1.7,-5.3 0.1,-9.8 -0.3,-23.9 -1,-30.7 -0.6,-6.3 -1.4,-5.5 -2.7,-15.3 -0.9,-7 -1.2,-12.8 -1.3,-16.7 2.9,0.8 7,1.4 11.7,0.7 2.3,-0.4 5.4,-0.9 8.7,-3 3.2,-2.1 5.1,-4.8 6,-6.3 2.7,0.6 6.6,2 10.7,4.6 z"
   id="path105" />
</g>
<g onClick={handleClick} 
   transform="translate(-748.26356,-600.06701)"
   id="RLUpper Back" style={getFillBySite('Rear Left Upper Back')}>
	<path
   class="st0"
   d="m 872.6,495.2 c 0.3,2.8 0.7,5 1,6.8 1.3,7.5 2.7,13 3,14.2 1.3,5 1.8,13.3 -1.5,27.5 2.7,0.3 6.9,0.8 12,1.5 22.4,3 28.8,4.7 38.5,5.5 7.4,0.6 18.4,0.7 32.2,-2 0,-47 0,-94 0,-141 0,4.7 0,9.3 0,14 -2.5,-0.5 -6.4,-1 -11.2,-0.5 -12.8,1.3 -16.7,10.4 -34.2,19 -22.1,10.8 -25.7,14.2 -28,16.6 -2.2,2.4 -11.4,12.1 -12.2,23.9 -0.3,2.9 -0.2,8.7 0.4,14.5 z"
   id="path108" />
</g>
<g onClick={handleClick} 
   transform="translate(-748.26356,-600.06701)"
   id="RLLower Back" style={getFillBySite('Rear Left Lower Back')}>
	<path
   class="st0"
   d="m 957.8,659.7 c -13.3,-2.2 -29,-6.1 -45.8,-12.7 -8.3,-3.3 -15.9,-6.8 -22.7,-10.3 -1.1,-10.8 -0.9,-19.4 -0.4,-25.2 0.5,-7.2 1.4,-10.4 1.2,-18 -0.1,-4.3 -0.2,-10.6 -2,-18.2 -2.7,-11.6 -8,-21 -8,-21 -1.7,-3.5 -3.3,-7 -5,-10.5 4.5,0.5 11.6,1.4 20.3,2.6 17.6,2.5 22.2,3.7 30.2,4.4 7.4,0.6 18.4,0.7 32.2,-2 0,36.9 0,73.9 0,110.9 z"
   id="path111" />
</g>
<g onClick={handleClick} 
   transform="translate(-748.26356,-600.06701)"
   id="RLGluteus" style={getFillBySite('Rear Left Gluteus')}>
	<path
   class="st0"
   d="m 957.8,711.7 c 0,-9.8 0,-19.6 0,-29.3 0,-7.6 0,-15.1 0,-22.7 -13.3,-2.2 -29,-6.1 -45.8,-12.7 -8.3,-3.3 -15.9,-6.8 -22.7,-10.3 -1.7,4.4 -3.2,9.7 -4.3,15.7 -2.1,11.8 -1.5,22.1 -0.4,29.5 -1.9,4.6 -4.2,12 -4.2,21.3 0,6 0.1,24.7 14.6,36.5 11.6,9.5 25.4,8.8 28.7,8.7 3.8,-0.2 11.4,-0.6 18.7,-5.3 14.6,-9.7 15.4,-28.9 15.4,-31.4 z"
   id="path114" />
</g>
<g onClick={handleClick} 
   transform="translate(-748.26356,-600.06701)"
   id="RRUpper Back" style={getFillBySite('Rear Right Upper Back')}>
	<path
   class="st0"
   d="m 1043,495.2 c -0.3,2.8 -0.7,5 -1,6.8 -1.3,7.5 -2.7,13 -3,14.2 -1.3,5 -1.8,13.3 1.5,27.5 -2.7,0.3 -6.9,0.8 -12,1.5 -22.4,3 -28.8,4.7 -38.5,5.5 -7.4,0.6 -18.4,0.7 -32.2,-2 0,-47 0,-94 0,-141 0,4.7 0,9.3 0,14 2.5,-0.5 6.4,-1 11.2,-0.5 12.8,1.3 16.7,10.4 34.2,19 22.1,10.8 25.7,14.2 28,16.6 2.2,2.4 11.4,12.1 12.2,23.9 0.3,2.9 0.2,8.7 -0.4,14.5 z"
   id="path117" />
</g>
<g onClick={handleClick} 
   transform="translate(-748.26356,-600.06701)"
   id="RRLower Back" style={getFillBySite('Rear Right Lower Back')}>
	<path
   class="st0"
   d="m 957.8,659.7 c 13.3,-2.2 29,-6.1 45.8,-12.7 8.3,-3.3 15.9,-6.8 22.7,-10.3 1.1,-10.8 0.9,-19.4 0.4,-25.2 -0.5,-7.2 -1.4,-10.4 -1.2,-18 0.1,-4.3 0.2,-10.6 2,-18.2 2.7,-11.6 8,-21 8,-21 1.7,-3.5 3.3,-7 5,-10.5 -4.5,0.5 -11.6,1.4 -20.3,2.6 -17.6,2.5 -22.2,3.7 -30.2,4.4 -7.4,0.6 -18.4,0.7 -32.2,-2 0,36.9 0,73.9 0,110.9 z"
   id="path120" />
</g>
<g onClick={handleClick} 
   transform="translate(-748.26356,-600.06701)"
   id="RRGluteus" style={getFillBySite('Rear Right Gluteus')}>
	<path
   class="st0"
   d="m 957.8,711.7 c 0,-9.8 0,-19.6 0,-29.3 0,-7.6 0,-15.1 0,-22.7 13.3,-2.2 29,-6.1 45.8,-12.7 8.3,-3.3 15.9,-6.8 22.7,-10.3 1.7,4.4 3.2,9.7 4.3,15.7 2.1,11.8 1.5,22.1 0.4,29.5 1.9,4.6 4.2,12 4.2,21.3 0,6 -0.1,24.7 -14.6,36.5 -11.6,9.5 -25.4,8.8 -28.7,8.7 -3.8,-0.2 -11.4,-0.6 -18.7,-5.3 -14.6,-9.7 -15.3,-28.9 -15.4,-31.4 z"
   id="path123" />
</g>
<g onClick={handleClick} 
   transform="translate(-748.26356,-600.06701)"
   id="RLLeg" style={getFillBySite('Rear Left Leg')}>
	<path
   class="st0"
   d="m 880.4,703.2 c -1.5,0.1 -2.4,13.1 -3.3,26.8 -0.1,2.1 -0.4,5.5 -0.5,10 -0.4,15.3 0.9,26.5 2.2,39 1.2,10.6 3.1,21.9 7,44.5 3.5,20.6 3.9,21.2 5,29.5 1.3,9.6 2.9,22.2 3,37.8 0.1,11.5 -0.7,27 -4.1,45.3 0.2,-1.4 0.7,-3.5 2.1,-5.6 3.6,-5.5 10.5,-6.3 12.5,-6.5 6.6,-0.7 11.5,1.9 16.5,4.5 5.7,3 14.1,7.4 18.5,17.2 0.4,1 0.8,2 0.8,2.1 2,5.8 2,11 1.7,14.4 -0.2,-7.4 -0.7,-15.1 -1.2,-23 -0.4,-5.6 -0.9,-11.1 -1.5,-16.5 1.1,-3.5 2.6,-8.6 4,-14.8 2.7,-11.6 3.9,-20.8 4.5,-26 2.3,-19.5 2.3,-30.8 3.8,-54.5 1.6,-25 2.1,-20.9 3,-39 1.4,-29.9 -0.6,-29.7 1.5,-48 1.2,-10 1.6,-13 1.8,-19.5 0.2,-4 0.1,-7.3 0.1,-9.3 0,1.9 -0.3,21.1 -15.5,31.3 -10.4,7 -21.6,5.7 -25.7,5.2 -4,-0.5 -17.6,-2.6 -26.5,-13.4 -11,-13.2 -8,-31.5 -9.7,-31.5 z"
   id="path126" />
</g>
<g onClick={handleClick} 
   transform="translate(-748.26356,-600.06701)"
   id="RLCalf" style={getFillBySite('Rear Left Calf')}>
	<path
   class="st0"
   d="m 942,1103.9 c -0.7,0.3 -1.7,0.8 -2.9,1.2 -1.9,0.8 -5.4,2.2 -8.8,2.9 -2.6,0.5 -8.9,1.3 -15.5,-1.6 -2.4,-1.1 -4.1,-2.3 -5.2,-3.2 -0.6,-1.1 -1.3,-2.8 -1.8,-5 -1,-4.8 -0.2,-8.8 0,-10 0.5,-2.7 0,-9.2 -1,-22.1 -1.1,-13.7 -4.6,-24.7 -9.2,-45 -4.4,-19.5 -7.3,-32.2 -8.5,-48.2 -1.1,-15.4 -0.3,-28.2 0.7,-36.7 0.2,-1.4 0.7,-3.5 2.1,-5.6 3.6,-5.5 10.5,-6.3 12.5,-6.5 6.6,-0.7 11.5,1.9 16.5,4.5 5.7,3 14.1,7.4 18.5,17.2 1.5,3.3 2.1,6.4 2.5,16.5 0.6,17.9 -0.5,31.9 -0.8,34.8 -0.8,10 -1,8.2 -3.5,32.5 -0.2,1.8 -0.6,9.3 -1.5,24.2 -0.3,4.6 -0.5,9.7 0.8,16 0.7,3.4 1.2,5.8 2.5,9 1.2,2.9 2.1,4.1 2.8,7 0.4,1.6 0.5,3.1 0.5,6.5 -0.1,2.9 -0.2,6.8 -0.7,11.6 z"
   id="path129" />
</g>
<g onClick={handleClick} 
   transform="translate(-748.26356,-600.06701)"
   id="RLFoot" style={getFillBySite('Rear Left Foot')}>
	<path
   class="st0"
   d="m 900.6,1116.4 c -1.5,2.1 -1.1,4.8 -1,5.3 0.1,0.8 0.7,3.5 3,5 2.1,1.3 3.4,0.1 7,1 2.8,0.7 2.8,1.7 6,3 2,0.8 4.3,1.1 8.7,1.7 5.2,0.6 7.8,1 10.1,0.6 2.3,-0.4 5.5,-0.9 7.9,-3.6 2.3,-2.5 2.8,-5.5 3.1,-7.5 0.5,-3.5 -0.1,-6.3 -0.2,-6.9 -0.7,-2.9 -1.8,-4 -2.6,-7.8 -0.3,-1.4 -0.4,-2.6 -0.5,-3.4 -0.7,0.3 -1.7,0.8 -2.9,1.2 -1.9,0.8 -5.4,2.2 -8.8,2.9 -2.6,0.5 -8.9,1.3 -15.5,-1.6 -2.4,-1.1 -4.1,-2.3 -5.2,-3.2 0.1,0.8 0.2,2.1 -0.1,3.6 -0.4,1.7 -1.2,2.9 -1.8,3.6 -0.9,1.3 -1.9,2.1 -3,3 -2.7,2.2 -3.2,1.8 -4.2,3.1 z"
   id="path132" />
</g>
<g onClick={handleClick} 
   transform="translate(-748.26356,-600.06701)"
   id="RRLeg" style={getFillBySite('Rear Right Leg')}>
	<path
   class="st0"
   d="m 1035.2,703.2 c 1.5,0.1 2.4,13.1 3.3,26.8 0.1,2.1 0.4,5.5 0.5,10 0.4,15.3 -0.9,26.5 -2.2,39 -1.2,10.6 -3.1,21.9 -7,44.5 -3.5,20.6 -3.9,21.2 -5,29.5 -1.3,9.6 -2.9,22.2 -3,37.8 -0.1,11.5 0.7,27 4.1,45.3 -0.2,-1.4 -0.7,-3.5 -2.1,-5.6 -3.6,-5.5 -10.5,-6.3 -12.5,-6.5 -6.6,-0.7 -11.5,1.9 -16.5,4.5 -5.7,3 -14.1,7.4 -18.5,17.2 -0.4,1 -0.8,2 -0.8,2.1 -2,5.8 -2,11 -1.7,14.4 0.2,-7.4 0.7,-15.1 1.2,-23 0.4,-5.6 0.9,-11.1 1.5,-16.5 -1.1,-3.5 -2.6,-8.6 -4,-14.8 -2.7,-11.6 -3.9,-20.8 -4.5,-26 -2.3,-19.5 -2.3,-30.8 -3.8,-54.5 -1.6,-25 -2.1,-20.9 -3,-39 -1.4,-29.9 0.6,-29.7 -1.5,-48 -1.2,-10 -1.6,-13 -1.8,-19.5 -0.2,-4 -0.1,-7.3 -0.1,-9.3 0,1.9 0.3,21.1 15.5,31.3 10.4,7 21.6,5.7 25.7,5.2 4,-0.5 17.6,-2.6 26.5,-13.4 11,-13.2 8,-31.5 9.7,-31.5 z"
   id="path135" />
</g>
<g onClick={handleClick} 
   transform="translate(-748.26356,-600.06701)"
   id="RRCalf" style={getFillBySite('Rear Right Calf')}>
	<path
   class="st0"
   d="m 973.6,1103.9 c 0.7,0.3 1.7,0.8 2.9,1.2 1.9,0.8 5.4,2.2 8.8,2.9 2.6,0.5 8.9,1.3 15.5,-1.6 2.4,-1.1 4.1,-2.3 5.2,-3.2 0.6,-1.1 1.3,-2.8 1.8,-5 1,-4.8 0.2,-8.8 0,-10 -0.5,-2.7 0,-9.2 1,-22.1 1.1,-13.7 4.6,-24.7 9.2,-45 4.4,-19.5 7.3,-32.2 8.5,-48.2 1.1,-15.4 0.3,-28.2 -0.7,-36.7 -0.2,-1.4 -0.7,-3.5 -2.1,-5.6 -3.6,-5.5 -10.5,-6.3 -12.5,-6.5 -6.6,-0.7 -11.5,1.9 -16.5,4.5 -5.7,3 -14.1,7.4 -18.5,17.2 -1.5,3.3 -2.1,6.4 -2.5,16.5 -0.6,17.9 0.5,31.9 0.8,34.8 0.8,10 1,8.2 3.5,32.5 0.2,1.8 0.6,9.3 1.5,24.2 0.3,4.6 0.5,9.7 -0.8,16 -0.7,3.4 -1.2,5.8 -2.5,9 -1.2,2.9 -2.1,4.1 -2.8,7 -0.4,1.6 -0.5,3.1 -0.5,6.5 0.1,2.9 0.2,6.8 0.7,11.6 z"
   id="path138" />
</g>
<g onClick={handleClick} 
   transform="translate(-748.26356,-600.06701)"
   id="RRFoot" style={getFillBySite('Rear Right Foot')}>
	<path
   class="st0"
   d="m 1015,1116.4 c 1.5,2.1 1.1,4.8 1,5.3 -0.1,0.8 -0.7,3.5 -3,5 -2.1,1.3 -3.4,0.1 -7,1 -2.8,0.7 -2.8,1.7 -6,3 -2,0.8 -4.3,1.1 -8.7,1.7 -5.2,0.6 -7.8,1 -10.1,0.6 -2.3,-0.4 -5.5,-0.9 -7.9,-3.6 -2.3,-2.5 -2.8,-5.5 -3.1,-7.5 -0.5,-3.5 0.1,-6.3 0.2,-6.9 0.7,-2.9 1.8,-4 2.6,-7.8 0.3,-1.4 0.4,-2.6 0.5,-3.4 0.7,0.3 1.7,0.8 2.9,1.2 1.9,0.8 5.4,2.2 8.8,2.9 2.6,0.5 8.9,1.3 15.5,-1.6 2.4,-1.1 4.1,-2.3 5.2,-3.2 -0.1,0.8 -0.2,2.1 0.1,3.6 0.4,1.7 1.2,2.9 1.8,3.6 0.9,1.3 1.9,2.1 3,3 2.7,2.2 3.3,1.8 4.2,3.1 z"
   id="path141" />
</g>
</svg>
</>
);    
   
}

export default Rear;