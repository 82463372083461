import React, { useState } from 'react'

import {Create, FormDataConsumer, SimpleForm, TextInput, DateInput, BooleanInput, ReferenceInput, SelectInput, NumberInput, required, minLength, maxLength} from 'react-admin';
import { Box } from '@material-ui/core';
import { useForm } from 'react-final-form';

import { useQuery, Loading, Error } from 'react-admin';


import { RaGrid } from 'ra-compact-ui';

import Front from '../wizard/Front.js';

import Rear from '../wizard/Rear.js';


const imageWidth = 200;
const imageHeight = Math.round(imageWidth / 0.471412);


export default ({updateForm, form}) => {
  const [selected, setSelected] = useState(form.lesion_position);
  const [selectedLesion, setSelectedLesion] = useState(form.lesion_position);

  const { data, loading, error } = useQuery({ 
    type: 'getList',
    resource: 'exams',
    payload: {
      pagination: { page: 1, perPage: 9999 },
      sort: {field: 'created_at', order: 'DESC'},
      filter: {}
    }
  });
  
  if (loading) return <Loading />;
  if (error) return  <Error/>;


  if (!data) return null;

  var exams = data.filter(({lesion}) => lesion.patient === form.patient); 
  exams = exams.filter(({diagnosed}) => diagnosed === false); 

  function handleBodyClick(value) {
  }

  function handleLesionClick(value) {
    updateForm('exam', value);
  }

  return (
    <>          
    <SimpleForm toolbar={false} onSubmit={(event) => event.preventDefault()}>

    <RaGrid container spacing={1} style={{ width: "100%" }} >
                <RaGrid item xs={4}>
                  <ReferenceInput allowEmpty label="Existing Patient" source="patient" defaultValue={form.patient} reference="patients" resettable perPage={1000} fullWidth sort={{ field: 'id', order: 'DESC' }} 
                    onChange={(event, key, payload) => {updateForm('exam', undefined);updateForm('patient', event.target.value)}} >
                    <SelectInput helperText="Choose an existing patient" validate={[required()]}  optionValue="id"  optionText={(p)=> 'Patient #'+p.id+ ' ('+p.birthday_year+')'} />
                  </ReferenceInput>
                </RaGrid>
                <RaGrid item xs={1} align="center">
                  <br/><strong>and</strong>
                </RaGrid>
                <RaGrid item xs={7}>
                <ReferenceInput allowEmpty label="Pending Exam" source="exam" reference="exams" defaultValue={form.exam} filter={{ patient: form.patient, diagnosed: false }} resettable perPage={1000} fullWidth sort={{ field: 'id', order: 'DESC' }} 
                  onChange={(event, key, payload) => {updateForm('exam', event.target.value); }} >
                    <SelectInput helperText="Choose a pending exam" validate={[required()]} optionValue="id"  optionText={(e)=> 'Exam #'+e.id+ ' on '+e.lesion.site+' ('+e.date+')'} />
                  </ReferenceInput>     
                 </RaGrid>
      </RaGrid>
      </SimpleForm>
    <Box aria-label="front_rear" display="flex" sx={{marginBottom: 50, marginLeft: 70, marginRight: 70, flexWrap: 'wrap', flexDirection: 'row', justifyContent: 'space-between' }}>


    
    <Front height={imageHeight} width={imageWidth} selected={selected} setSelected={handleBodyClick} selectedLesion={selectedLesion} setSelectedLesion={handleLesionClick} color="primary" exams={exams} mode="diagnosis"/>
      <Rear height={imageHeight} width={imageWidth} selected={selected} setSelected={handleBodyClick} selectedLesion={selectedLesion} setSelectedLesion={handleLesionClick} color="primary" exams={exams} mode="diagnosis"/>

  
          </Box>
    <Box aria-label="selected" display="flex" sx={{marginBottom: 50, marginLeft: 10, marginRight: 10, flexWrap: 'wrap', flexDirection: 'row', justifyContent: 'space-betweens' }}>


          <div style={{width: '100%'}} align="center">
            {selected && (<><h2>Selected</h2><h2>{selected}</h2></>)}
          </div>

    </Box>
    </>
  )
}