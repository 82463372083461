import * as React from 'react';
import { FC } from 'react';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { useTranslate } from 'react-admin';

import CardWithIcon from './CardWithIcon';

interface Props {
    value?: string;
}

const LastMonthExams: FC<Props> = ({ value }) => {
    const translate = useTranslate();
    return (
        <CardWithIcon
            to="/exams"
            icon={AssignmentIcon}
            title={translate('dashboard.lastmonth_exams')}
            subtitle={value}
        />
    );
};

export default LastMonthExams;