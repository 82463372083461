import * as React from 'react';
import { createElement } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from '@material-ui/core';
import { MenuItemLink, getResources } from 'react-admin';
import { withRouter } from 'react-router-dom';
import LabelIcon from '@material-ui/icons/Label';
import DashboardIcon from '@material-ui/icons/Dashboard'; 
import GavelIcon from '@material-ui/icons/Gavel';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import { useTranslate } from 'react-admin';


const Menu = ({ onMenuClick, logout }) => {
    const translate = useTranslate();

    const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const open = useSelector(state => state.admin.ui.sidebarOpen);
    const resources = useSelector(getResources);
    return (
        <div>
            <MenuItemLink
                to="/"
                primaryText="Dashboard"
                leftIcon={<DashboardIcon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />
            <MenuItemLink
                to="/wizard"
                primaryText="Wizard"
                leftIcon={<LibraryAddIcon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />
            <MenuItemLink
                to="/diagnosis"
                primaryText="Diagnosis"
                leftIcon={<GavelIcon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />
            {resources.map(resource => (
                <MenuItemLink
                    key={resource.name}
                    to={`/${resource.name}`}
                    primaryText={resource.options && resource.options.label || resource.name}
                    //primaryText={translate(resource.options && resource.options.label || resource.name)}
                    leftIcon={createElement(resource.icon)}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                />
            ))}

        </div>
    );
}

export default withRouter(Menu);