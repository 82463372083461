import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { Box } from '@material-ui/core';

import { fetchJsonWithAuthToken } from '../providers';
import { useDispatch } from 'react-redux';
import { useNotify, useRedirect, fetchStart, fetchEnd } from 'react-admin';

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  button: {
    //marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return [(<>Patient<br/>Anamnestic</>), (<>Lesion<br/>Position</>), 
  (<>Macro<br/>Photo</>), (<>Lesion<br/>Anamnestic</>), (<>Take<br/>DCT</>), (<>Complete<br/>Wizard</>)];
}


export default function Nav(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const notify = useNotify();
  const redirect = useRedirect();

  const [loading, setLoading] = useState(false);

  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);

  const currentStep = props.currentStep-1;
  const steps = getSteps();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleAgree = () => {
    // post to backend
    let data = props.state.form;
    console.log(data);
    setLoading(true);
    dispatch(fetchStart()); // start the global loading indicator 
    fetchJsonWithAuthToken(`/api/wizard/`, { method: 'POST', body: JSON.stringify(data) })
            .then(() => {
                notify('Wizard saved');
                props.nextStep();
            })
            .catch((e) => {
                notify('Error: wizard not saved', { type: 'warning' })

            })
            .finally(() => {
                setLoading(false);
                dispatch(fetchEnd()); // stop the global loading indicator
            });
    //
    handleClose();
  };
  
  const handleDisagree = () => {
    console.log("No");
    handleClose();
  };

  const handleValidate = (step) => {
    let data = props.state.form;
    console.log(currentStep)

    if (currentStep===0 && data?.patient && data?.sex && data?.phototype)
      props.nextStep();
    else if (currentStep===1 && (data?.lesion_position || data?.lesion_id))
      props.nextStep();
    else if (currentStep===2 && data?.macro_photo)
      props.nextStep();
    else if (currentStep===3)
      props.nextStep();
    else if (currentStep===4 && data?.hash)
      handleClickOpen();
    else handleOpenAlert();
  };

  return (
    <div className={classes.root}>
      
      <Stepper nonLinear activeStep={currentStep} style={{width: '100%'}}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={label} {...stepProps}  alternativeLabel>
              <StepButton color="inherit" disabled>  {/*={index===steps.length -1}>onClick={() => props.goToStep(index+1)} */}
              <StepLabel {...labelProps} style={{whiteSpace: 'pre-line'}}>{label}</StepLabel>
              </StepButton>
            </Step>
          );
        })}
        <Box sx={{ ml: 1 }} /> 
          <div>
        {currentStep === steps.length -1 ? (
          <div>
          <ButtonGroup variant="contained" aria-label="outlined primary button group">
            <Button 
              disabled variant="contained" color="primary" className={classes.button}>
              Back
            </Button>
            <Button  
              disabled variant="contained" color="primary" className={classes.button} >
              Save
            </Button>
          </ButtonGroup>

          </div>
        ) : (
          <div>
            <div>
            <ButtonGroup variant="contained" aria-label="outlined primary button group">

              <Button variant="contained"
                color="primary" disabled={currentStep === 0} onClick={props.previousStep} className={classes.button}>
                Back
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={currentStep === steps.length-2 ? handleValidate : handleValidate}
                className={classes.button}
                disabled={currentStep === steps.length-2 ? loading : false}
              >
                {currentStep === steps.length-2 ? 'Save' : 'Next'}
              </Button>
            </ButtonGroup>
            </div>
          </div>
        )}
      </div>
      <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
          Are you sure?
          </DialogTitle>
          <DialogActions>
          <Button onClick={handleAgree} color="primary" autoFocus>
              Yes
            </Button>
            <Button onClick={handleDisagree} color="primary">
              No
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openAlert}
          onClose={handleCloseAlert}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
          Please fill all required fields
          </DialogTitle>
          <DialogActions>
          <Button onClick={handleCloseAlert} color="primary" autoFocus>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Stepper>
    
    </div>
  );
}