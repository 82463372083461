import React, { Children, Fragment, cloneElement, memo } from 'react';
import BookIcon from '@material-ui/icons/Book';
import Chip from '@material-ui/core/Chip';
import { useMediaQuery, makeStyles } from '@material-ui/core';
import lodashGet from 'lodash/get';
import jsonExport from 'jsonexport/dist';
import {
    BooleanField,
    BulkDeleteButton,
    BulkExportButton,
    ChipField,
    Datagrid,
    DateField,
    downloadCSV,
    EditButton,
    DateInput,
    Filter,
    List,
    NumberField,
    ReferenceArrayField,
    SearchInput,
    ShowButton,
    SimpleList,
    SingleFieldList,
    TextField,
    TextInput,
    useTranslate,
} from 'react-admin'; // eslint-disable-line import/no-unresolved

import ResetViewsButton from './ResetViewsButton';
import ThumbnailField from './ThumbnailField';
export const ImageIcon = BookIcon;

const useQuickFilterStyles = makeStyles(theme => ({
    chip: {
        marginBottom: theme.spacing(1),
    },
}));
const QuickFilter = ({ label }) => {
    const translate = useTranslate();
    const classes = useQuickFilterStyles();
    return <Chip className={classes.chip} label={translate(label)} />;
};

const ImageFilter = props => (
    <Filter {...props}>
        <DateInput alwaysOn
            source="created_at"
        />
       
    </Filter>
);

const exporter = images => {
    const data = images.map(image => ({
        ...image,
    }));
    jsonExport(data, (err, csv) => downloadCSV(csv, 'images'));
};

const useStyles = makeStyles(theme => ({
    title: {
        maxWidth: '20em',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    hiddenOnSmallScreens: {
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    publishedAt: { fontStyle: 'italic' },
}));

const ImageListBulkActions = memo(props => (
    <Fragment>
       
        <BulkExportButton {...props} />
    </Fragment>
));

const useImageListActionToolbarStyles = makeStyles({
    toolbar: {
        alignItems: 'center',
        display: 'flex',
        marginTop: -1,
        marginBottom: -1,
    },
});

const ImageListActionToolbar = ({ children, ...props }) => {
    const classes = useImageListActionToolbarStyles();
    return (
        <div className={classes.toolbar}>
            {Children.map(children, button => cloneElement(button, props))}
        </div>
    );
};

const rowClick = (id, basePath, record) => {
    if (record.commentable) {
        return ;
    }

    return ;
};

const ImagePanel = ({ id, record, resource }) => (
    <div dangerouslySetInnerHTML={{ __html: 'Hash: '+record.hash+' - Device: ' +record.device}} />
);


const ImageList = props => {
    const classes = useStyles();
    const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));

    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

    return (
        <List
            {...props}
            bulkActionButtons={<ImageListBulkActions />}
            filters={<ImageFilter />}
            sort={{ field: 'created_at', order: 'DESC' }}
            exporter={exporter}
        >
            {isXSmall ? (
                <SimpleList
                    primaryText={record => <ThumbnailField/>}
                    secondaryText={record => record.hash}
                    tertiaryText={record =>
                        new Date(record.created_at).toLocaleDateString()
                    }
                />
                ) : isSmall ? (
                    <Datagrid rowClick={rowClick} expand={ImagePanel} optimized>
                    <TextField source="id" />
                    <ThumbnailField/>


                    <DateField
                        source="created_at"
                        cellClassName={classes.created_at}
                    />
                    </Datagrid>
            ) : (
                <Datagrid rowClick={rowClick} expand={ImagePanel} optimized>
                    <TextField source="id" />
                    <ThumbnailField/>


                    <DateField
                        source="created_at"
                        cellClassName={classes.created_at}
                    />

                    <ImageListActionToolbar>
                        
                    </ImageListActionToolbar>
                </Datagrid>
            )}
        </List>
    );
};

export default ImageList;
