import * as React from 'react';
import { AppBar } from 'react-admin';
import { Tooltip, IconButton, Box, Typography } from '@material-ui/core';
import CloudDoneIcon from '@material-ui/icons/CloudDone';
import CloudOffIcon from '@material-ui/icons/CloudOff';
import InfoIcon from '@material-ui/icons/Info';
import CreateIcon from '@material-ui/icons/Create';
import { Link } from 'react-router-dom';

import { Detector } from "react-detect-offline";
import GitInfo from 'react-git-info/macro';

const gitInfo = GitInfo();

const polling = {
    enabled: true,
    url: window.location.origin+'/api/status/',

    interval: 5000,
    timeout: 3000
  };

const MyAppBar = props => {
    return (
    
    <AppBar {...props}>
	      <Box flex="1">
            <Typography variant="h6" id="react-admin-title">INPECOTEST Webapp </Typography>
        </Box>
        <Tooltip title={"Version: " + gitInfo.commit.shortHash}><IconButton color='inherit'><InfoIcon/></IconButton></Tooltip>
        <Detector polling={polling} onChange={(online) => {console.log(online);}} render={({online}) => (
            <div>
              {online ? (<Tooltip title={"Backend is online"}><IconButton color='inherit'><CloudDoneIcon/></IconButton></Tooltip>)
               : (<Tooltip title={"Backend is unreachable"}><IconButton color='inherit'><CloudOffIcon/></IconButton></Tooltip>)
             } 
            </div>
          
        ) 
            } 
          />   
    </AppBar>
    );
};

export default MyAppBar;
