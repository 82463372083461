import MemoryIcon from '@material-ui/icons/Memory';

/*
import ResultCreate from './ResultCreate';
import ResultEdit from './ResultEdit';
import ResultList from './ResultList';
import ResultShow from './ResultShow';
*/
export default {
  /*  list: ResultList,
    create: ResultCreate,
    edit: ResultEdit,
    show: ResultShow,
  */  icon: MemoryIcon,
};
