import * as React from 'react';
import { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FieldProps } from 'react-admin';

const useStyles = makeStyles({
    root: { width: 250, maxWidth: 250, maxHeight: 250 },
});

const ThumbnailField: FC<FieldProps<Image>> = ({ record }) => {
    const classes = useStyles();
    return record ? (
        <img src={record.file} className={classes.root} alt="" />
    ) : null;
};

export default ThumbnailField;