// in src/Dashboard.js
import React, {useCallback, useEffect, useState } from "react";
import { useVersion, useDataProvider } from 'react-admin';
import { subDays } from 'date-fns';
import { useMediaQuery } from '@material-ui/core';

import NewImages from './NewImages';
import NewPatients from './NewPatients';
import LastMonthExams from "./LastMonthExams";
import PendingExams from "./PendingExams";
import PendingImages from "./PendingImages";
import ImageChart from "./ImageChart";

const styles = {
    flex: { display: 'flex' },
    flexColumn: { display: 'flex', flexDirection: 'column' },
    leftCol: { flex: 1, marginRight: '0.5em' },
    rightCol: { flex: 1, marginLeft: '0.5em' },
    singleCol: { marginTop: '1em', marginBottom: '1em' },
};

const Spacer = () => <span style={{ width: '1em' }} />;
const VerticalSpacer = () => <span style={{ height: '1em' }} />;

export default () => {
    const [state, setState] = useState({});
    const version = useVersion();
    const dataProvider = useDataProvider();

    const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('md'));

    const fetchExams = useCallback(async () => {
        const aMonthAgo = subDays(new Date(), 30);
        const { data: exams } = await dataProvider.getList(
            'exams',
            {
                filter: { date__gte: aMonthAgo.toISOString() },
                sort: { field: 'date', order: 'DESC' },
                pagination: { page: 1, perPage: 100000 },
            }
        );
        const nbRecentExams = exams.reduce((nb) => ++nb, 0);
        const recentExams = exams;
        setState(state => ({ ...state, recentExams, nbRecentExams }));
    }, [dataProvider]);

    const fetchPendingExams = useCallback(async () => {
        const { data: pendingexams } = await dataProvider.getList(
            'exams',
            {
                filter: { diagnosed: false },
                sort: { field: 'created_at', order: 'DESC' },
                pagination: { page: 1, perPage: 100000 },
            }
        );
        const nbPendingExams = pendingexams.reduce((nb) => ++nb, 0);
        const pendingExams = pendingexams.slice(0, Math.min(10, pendingexams.length));
        setState(state => ({ ...state, pendingExams, nbPendingExams }));
    }, [dataProvider]);
    
    const fetchRecentImages = useCallback(async () => {
        const { data: images } = await dataProvider.getList(
            'images',
            {
                filter: {},
                sort: { field: 'created_at', order: 'DESC' },
                pagination: { page: 1, perPage: 100000 },
            }
        );
        const nbRecentsImages = images.reduce((nb) => ++nb, 0);
        const recentImages = images;
        setState(state => ({ ...state, recentImages, nbRecentsImages }));
    }, [dataProvider]);

    const fetchImages = useCallback(async () => {
        const { data: images } = await dataProvider.getList(
            'images',
            {
                filter: { exam__isnull: true },
                sort: { field: 'created_at', order: 'DESC' },
                pagination: { page: 1, perPage: 100000 },
            }
        );
        const nbNewImages = images.reduce((nb) => ++nb, 0);
        const newImages = images.slice(0, Math.min(10, images.length));
        setState(state => ({ ...state, newImages, nbNewImages }));
    }, [dataProvider]);

    const fetchPatients = useCallback(async () => {
        const aMonthAgo = subDays(new Date(), 30);
        const { data: patients } = await dataProvider.getList(
            'patients',
            {
                filter: { date__gte: aMonthAgo.toISOString() },
                sort: { field: 'created_at', order: 'DESC' },
                pagination: { page: 1, perPage: 100000 },
            }
        );
        const nbNewPatients = patients.reduce((nb) => ++nb, 0);
        const newPatients = patients.slice(0, Math.min(10, patients.length));
        setState(state => ({ ...state, newPatients, nbNewPatients }));
    }, [dataProvider]);

    useEffect(() => {
        fetchExams();
        fetchPendingExams();
        fetchImages();
        fetchRecentImages();
        fetchPatients();
    }, [version]);


    const {
        recentExams,
        nbRecentExams,
        pendingExams,
        nbPendingExams,
        newImages,
        nbNewImages,
        recentImages,
        nbRecentImages,
        nbNewPatients
    } = state;

    return isXSmall ? (
    <div>
        <div style={styles.flexColumn}>
            <LastMonthExams value={nbRecentExams} />
            <VerticalSpacer />
            <PendingExams exams={pendingExams}  nb={nbPendingExams}/> 
            <VerticalSpacer />
            <PendingImages images={newImages} nb={nbNewImages} />
        </div>
    </div>
) : isSmall ? (
    <div style={styles.flexColumn}>
        <VerticalSpacer />
        <div style={styles.flex}>
            <LastMonthExams value={nbRecentExams} />
            <Spacer />
            <NewImages value={nbNewImages} />
            <Spacer />
            <NewPatients value={nbNewPatients} />
        </div>
        <VerticalSpacer />
        <div style={styles.flexColumn}>
            <ImageChart images={recentImages} exams={recentExams}/>
        </div>
        <VerticalSpacer />
        <div style={styles.flex}>
            <PendingImages images={newImages} nb={nbNewImages} />
            <Spacer />
            <PendingExams exams={pendingExams}  nb={nbPendingExams}/>           
        </div>

    </div>
) : (
           <>
           <div style={styles.flex}>
               <div style={styles.leftCol}>
                   <div style={styles.flex}>
                        <LastMonthExams value={nbRecentExams} />
                        <Spacer />
                        <NewImages value={nbNewImages} />
                   </div>
                   <div style={styles.singleCol}>
                        <ImageChart images={recentImages} exams={recentExams}/>
                   </div>
                   <div style={styles.singleCol}>
                        <PendingImages images={newImages} nb={nbNewImages} />
                   </div>
               </div>
               <div style={styles.rightCol}>
                   <div style={styles.flex}>
                       <NewPatients value={nbNewPatients} />
                      
                   </div>
                   <div style={styles.singleCol}>
                        <PendingExams exams={pendingExams}  nb={nbPendingExams}/> 
                   </div>
               </div>
           </div>
       </>
);
}