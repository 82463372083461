import React, { useState } from 'react'

import { TextInput, SimpleForm, required, minLength, maxLength} from 'react-admin';
import { Box, TextField } from '@material-ui/core';

import QrReader from 'react-qr-reader';

export default ({updateForm, form}) => {  
  const [hash, setHash] = useState();

  const handleScan = data => {
    //console.log(data);
    if (data) {
      setHash(data);
      updateForm('hash',  data);
    }
  }
  const handleError = err => {
    console.error(err)
  }
  return (
    <>
    <Box aria-label="qrcode" display="flex" sx={{marginBottom: 0, marginLeft: 10, marginRight: 10, flexWrap: 'wrap', flexDirection: 'row', justifyContent: 'center' }}>
      <QrReader
          delay={300}
          onError={handleError}
          onScan={handleScan}
          style={{ width: '500px' }}
        />      
    </Box>
    <Box aria-label="hash" display="flex" sx={{marginTop: 30, marginLeft: 10, marginRight: 10, flexWrap: 'wrap', flexDirection: 'row', justifyContent: 'center' }}>

      <TextField 
				type='text' 
				value={hash}
        defaultValue={form?.hash}
				variant='outlined'
        resettable
        fullWidth   
        style={{width: '500px'}}
        onKeyDown={e => handleScan(e.target.value)}
        onKeyUp={e => handleScan(e.target.value)}
				inputProps={
					{ 
            //readOnly: true, 
          }
				}
			/>  
    </Box>
    <Box aria-label="label" display="flex" sx={{marginLeft: 10, marginRight: 10, flexWrap: 'wrap', flexDirection: 'row', justifyContent: 'center' }}>
      <h2>Frame the QRCode on the DCT device</h2>
    </Box>
    </>
  )
}