// in src/Diagnosis.js
import React, {useCallback, useEffect, useState } from "react";
import { useVersion, useDataProvider } from 'react-admin';
import { subDays } from 'date-fns';
import { useMediaQuery } from '@material-ui/core';

import MultiStep from "./MultiStep";


const styles = {
    flex: { display: 'flex' },
    flexColumn: { display: 'flex', flexDirection: 'column' },
    leftCol: { flex: 1, marginRight: '0.5em' },
    rightCol: { flex: 1, marginLeft: '0.5em' },
    singleCol: { marginTop: '1em', marginBottom: '1em' },
};

const Spacer = () => <span style={{ width: '1em' }} />;
const VerticalSpacer = () => <span style={{ height: '1em' }} />;

export default (props) => {

    let hash = props?.match?.params?.hash;


    const [state, setState] = useState({});
    const version = useVersion();
    const dataProvider = useDataProvider();

    const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('md'));
    
    return   (
        <>
           <MultiStep hash={hash}/>
        </>
);
}