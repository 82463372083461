import React, { Fragment, useState, useEffect } from 'react';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from "react-router-dom";
import Nav from './Nav';


import styles from './diagnosis.less';
import transitions from './transitions.less';

import CardWithIcon from './CardWithIcon';

import StepWizard from "react-step-wizard";
import StepOne from './StepOne'
import StepTwo from './StepTwo'
import StepThree from './StepThree'

const MultiStep = ({exam, patient, lesion}) => {
    const [state, updateState] = useState({
        form: {exam: exam, patient: patient, lesion_position: lesion},
        transitions: {
            enterRight: `${transitions.animated} ${transitions.enterRight}`,
            enterLeft: `${transitions.animated} ${transitions.enterLeft}`,
            exitRight: `${transitions.animated} ${transitions.exitRight}`,
            exitLeft: `${transitions.animated} ${transitions.exitLeft}`,
            intro: `${transitions.animated} ${transitions.intro}`,
        },
    });
    
    const updateForm = (key, value) => {
        //console.log(key, value)
        const { form } = state;

        form[key] = value;
        updateState({
            ...state,
            form,
        });
    };

    // Do something on step change
    const onStepChange = (stats) => {
        console.log(state.form);
    };

    const setInstance = SW => updateState({
        ...state,
        SW,
    });
 
    console.log(state.form)
    return (
        <>
        <StepWizard
            onStepChange={onStepChange}
            isHashEnabled={false}
            //transitions={state.transitions} // comment out for default transitions
            nav={<Nav state={state}/>}
            instance={setInstance}
            className='reversed'
            >      
                <StepOne updateForm={updateForm} form={state.form}/>
                <StepTwo updateForm={updateForm} />
                <StepThree updateForm={updateForm}/>
            </StepWizard>
</>
    );
};

export default MultiStep;


/**
 * Stats Component - to illustrate the possible functions
 * Could be used for nav buttons or overview
 */
const Stats = ({
    currentStep,
    firstStep,
    goToStep,
    lastStep,
    nextStep,
    previousStep,
    totalSteps,
    step,
}) => (
    <div>
        <hr />
        { step > 1 &&
            <button className='btn btn-default btn-block' onClick={previousStep}>Go Back</button>
        }
        { step < totalSteps ?
            <button className='btn btn-primary btn-block' onClick={nextStep}>Continue</button>
            :
            <button className='btn btn-success btn-block' onClick={nextStep}>Finish</button>
        }
        <hr />
        <div style={{ fontSize: '21px', fontWeight: '200' }}>
            <h4>Other Functions</h4>
            <div>Current Step: {currentStep}</div>
            <div>Total Steps: {totalSteps}</div>
            <button className='btn btn-block btn-default' onClick={firstStep}>First Step</button>
            <button className='btn btn-block btn-default' onClick={lastStep}>Last Step</button>
            <button className='btn btn-block btn-default' onClick={() => goToStep(2)}>Go to Step 2</button>
        </div>
    </div>
);

const Progress = (props) => {
    const [state, updateState] = useState({
        isActiveClass: '',
        timeout: null,
    });

    useEffect(() => {
        const { timeout } = state;

        if (props.isActive && !timeout) {
            updateState({
                isActiveClass: styles.loaded,
                timeout: setTimeout(() => {
                    props.nextStep();
                }, 3000),
            });
        } else if (!props.isActive && timeout) {
            clearTimeout(timeout);
            updateState({
                isActiveClass: '',
                timeout: null,
            });
        }
    });

    return (
        <div className={styles['progress-wrapper']}>
            <p className='text-center'>Automated Progress...</p>
            <div className={`${styles.progress} ${state.isActiveClass}`}>
                <div className={`${styles['progress-bar']} progress-bar-striped`} />
            </div>
        </div>
    );
};

const Last = (props) => {
    const submit = () => {
        alert('You did it! Yay!') // eslint-disable-line
    };

    return (
        <div>
            <div className={'text-center'}>
                <h3>This is the last step in this example!</h3>
                <hr />
         
            </div>
            <Stats step={4} {...props} nextStep={submit} />
        </div>
    );
};
