import englishMessages from 'ra-language-english';

export const messages = {
    simple: {
        action: {
            close: 'Close',
            resetViews: 'Reset views',
        },
        'create-post': 'New post',
    },
    ...englishMessages,
    dashboard: {
        new_patients: 'New Patients',
        new_images: 'New Images',
        pending_images: 'Pending Images',
        pending_exams: 'Pending Exams',
        lastmonth_exams: 'Last Month Exams',
        all_images: 'All Images',
        all_exams: 'All Exams',
        month_history: 'Last month Images and Exams',
    },
    resources: {
        posts: {
            name: 'Post |||| Posts',
            fields: {
                average_note: 'Average note',
                body: 'Body',
                comments: 'Comments',
                commentable: 'Commentable',
                commentable_short: 'Com.',
                created_at: 'Created at',
                notifications: 'Notifications recipients',
                nb_view: 'Nb views',
                password: 'Password (if protected post)',
                pictures: 'Related Pictures',
                published_at: 'Published at',
                teaser: 'Teaser',
                tags: 'Tags',
                title: 'Title',
                views: 'Views',
                authors: 'Authors',
            },
        },
        comments: {
            name: 'Comment |||| Comments',
            fields: {
                body: 'Body',
                created_at: 'Created at',
                post_id: 'Posts',
                author: {
                    name: 'Author',
                },
            },
        },
        patients: {
            name: 'Patient |||| Patients',
            fields: {
                name: 'Name',
                role: 'Role',
                personal_history_of_mm: 'Personal history of MM',
                family_history_of_mm: 'Family history of MM',
                pheomelanine: 'Pheomelanine',
                blond_hair: 'Blond hair',
                green_light_blue_blue_eyes: 'Green/Light blue/Blue eyes',
                nevi_right_arm: 'Nevi right arm',
                pos_history_sunburns_childhood: 'Pos history sunburnschildhood',
                red_wine_intake: 'Red wine intake',
                smoke: 'Smoke',
                age: 'Age',
                bmi: 'BMI',
                created_at: 'Created at',
                sex: 'Sex',
                phototype: 'Phototype',


            },
        exams: {
            name: 'Exam |||| Exams',
            fields: {
         
                
                    date: 'Date',
                    evolution: 'Evolution',
                    infection: 'Infection',
                    blood: 'Blood',
                    itch: 'Itch',
                    rapid_growth: 'Rapid Growth',
                
            },
        },
        },

    },
    post: {
        list: {
            search: 'Search',
        },
        form: {
            summary: 'Summary',
            body: 'Body',
            miscellaneous: 'Miscellaneous',
            comments: 'Comments',
        },
        edit: {
            title: 'Post "%{title}"',
        },
        action: {
            save_and_edit: 'Save and Edit',
            save_and_add: 'Save and Add',
            save_and_show: 'Save and Show',
            save_with_average_note: 'Save with Note',
        },
    },
    comment: {
        list: {
            about: 'About',
        },
    },
    patient: {
        list: {
            search: 'Search',
        },
        form: {
            summary: 'Summary',
            address: 'Address',
            anamnesticdata: 'Anamnestic Data',
            privacy: 'Privacy',
        },
        edit: {
            title: 'Patient "%{title}"',
        },
        action: {
            save_and_add: 'Save and Add',
            save_and_edit: 'Save and Edit',
            save_and_show: 'Save and Show',
        },
    },
    exam: {
        list: {
            search: 'Search',
        },
        form: {
            summary: 'Summary',
            events: 'Events',

        },
        edit: {
            title: 'Exam "%{title}"',
        },
        action: {
            save_and_add: 'Save and Add',
            save_and_edit: 'Save and Edit',
            save_and_show: 'Save and Show',
        },
        
    },
};

export default messages;
