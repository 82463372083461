import ImageIcon from '@material-ui/icons/CameraEnhanceOutlined';
import ImageCreate from './ImageCreate';
import ImageEdit from './ImageEdit';
import ImageList from './ImageList';
import ImageShow from './ImageShow';

export default {
    list: ImageList,
    //create: ImageCreate,
    //edit: ImageEdit,
    //show: ImageShow,
    icon: ImageIcon,
};
