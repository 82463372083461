/* eslint react/jsx-key: off */
import PeopleIcon from '@material-ui/icons/People';
import memoize from 'lodash/memoize';
import { useMediaQuery } from '@material-ui/core';
import React from 'react';
import {
    BulkDeleteWithConfirmButton,
    Datagrid,
    Filter,
    List,
    SearchInput,
    SimpleList,
    TextField,
    NumberInput,
    EmailField,
    DateField,
    TextInput,
} from 'react-admin';

import PatientEditEmbedded from './PatientEditEmbedded';
export const PatientIcon = PeopleIcon;

const PatientFilter = ({ permissions, ...props }) => (
    <Filter {...props}>
        <NumberInput alwaysOn
            source="birthday_year"
        />
    </Filter>
);



const PatientBulkActionButtons = props => (
    <BulkDeleteWithConfirmButton {...props} />
);

const rowClick = memoize(permissions => (id, basePath, record) => {
    return permissions === 'admin'
        ? Promise.resolve('edit')
        : Promise.resolve('edit');
});

const PatientList = ({ permissions, ...props }) => {
    
    const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('md'));
    
    return (
    <List
        {...props}
        filters={<PatientFilter permissions={permissions} />}
        filterDefaultValues={{ role: 'user' }}
        sort={{ field: 'id,birthday_year', order: 'DESC' }}
        //aside={<Aside />}
        bulkActionButtons={false}
    >
        {isXSmall ? (
            <SimpleList
                primaryText={record => record.id }
                secondaryText={record => record.birthday_year }
            />
        ) : isSmall ? (
                <Datagrid rowClick={rowClick(permissions)}
                expand={false}
                optimized>
                        <TextField source="id" />
                        <TextField source="birthday_year" />
                        <TextField source="notes" />
                </Datagrid>
                
            ) : (
                <Datagrid rowClick={rowClick(permissions)}
                expand={false}
                optimized>
                        <TextField source="id" />
                        <TextField source="birthday_year" />
                        <TextField source="notes" />
                </Datagrid>
                
            )
        
        }
    </List>
)};

export default PatientList;
