/* eslint react/jsx-key: off */
import React from 'react';
import PropTypes from 'prop-types';
import {
    CloneButton,
    DeleteWithConfirmButton,
    Edit,
    FormTab,
    required,
    SaveButton,
    ShowButton,
    TabbedForm,
    TextInput,
    email,
    DateInput,
    DateField,
    Toolbar,
    BooleanInput,
    NumberInput,
    SimpleFormIterator,
    FormDataConsumer,
    ArrayInput,
    SelectInput,
    minValue,
    maxValue,
    TopToolbar,
    CheckboxGroupInput,
    ReferenceManyField, 
} from 'react-admin';
import { RaGrid } from 'ra-compact-ui';
import { makeStyles } from '@material-ui/core/styles';

import PatientTitle from './PatientTitle';
import Aside from './Aside';

import SignatureCanvas from 'react-signature-canvas'
import PolicyIcon from '@material-ui/icons/Policy'; 

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import { years, SEX_CHOICES, PHOTOTYPE_CHOICES, BMI_CHOICES } from '../wizard/helpers';


const useToolbarStyles = makeStyles({
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
});

/**
 * Custom Toolbar for the Edit form
 *
 * Save with undo, but delete with confirm
 */
const PatientEditToolbar = props => {
    const classes = useToolbarStyles();
    return (
        <Toolbar {...props} classes={classes}>
            <SaveButton />
            <DeleteWithConfirmButton />
        </Toolbar>
    );
};

const EditActions = ({ basePath, data, hasShow }) => (
    <TopToolbar>
        <CloneButton
            className="button-clone"
            basePath={basePath}
            record={data}
        />
        <ShowButton basePath={basePath} record={data} />
    </TopToolbar>
);
const validateEmail = email();


const PatientEdit = ({ permissions, ...props }) => (
    <Edit
        title={<PatientTitle />}
        aside={<Aside />}
        actions={<EditActions />}
        {...props}
    >
       <TabbedForm
            toolbar={<PatientEditToolbar permissions={permissions} {...props} />}
        >
            <FormTab label="patient.form.summary" path="">
            <SelectInput helperText="Create a new patient" label="New Patient (birthday year)" fullWidth source="birthday_year" 
                    optionValue="id"
                    optionText="text"
                                        validate={[required(), minValue(1900), maxValue(2022)]}
                                        choices={years()}
                                        min={1900} max={2022} step={1}
                                        />                        <TextInput source="notes"  />
            </FormTab>
           
        </TabbedForm>
    </Edit>
);

PatientEdit.propTypes = {
    id: PropTypes.any.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    permissions: PropTypes.string,
};

export default PatientEdit;
