import DevicesIcon from '@material-ui/icons/Devices';

/*
import DeviceCreate from './DeviceCreate';
import DeviceShow from './DeviceShow';
*/
import DeviceEdit from './DeviceEdit';
import DeviceList from './DeviceList';

export default {
    list: DeviceList,
   // create: DeviceCreate,
    edit: DeviceEdit,
  //  show: DeviceShow,
    icon: DevicesIcon,
};
