import React, { Children, Fragment, cloneElement, memo } from 'react';
import BookIcon from '@material-ui/icons/Book';
import Chip from '@material-ui/core/Chip';
import { useMediaQuery, makeStyles } from '@material-ui/core';
import lodashGet from 'lodash/get';
import jsonExport from 'jsonexport/dist';
import {
    BooleanField,
    BulkDeleteButton,
    BulkExportButton,
    ChipField,
    Datagrid,
    DateField,
    downloadCSV,
    EditButton,
    ReferenceField,
    FunctionField,
    Filter,
    List,
    NumberField,
    ReferenceArrayField,
    SearchInput,
    ShowButton,
    DateInput,
    BooleanInput,
    SimpleList,
    SingleFieldList,
    RichTextField,
    TextField,
    TextInput,
    useTranslate,
} from 'react-admin'; // eslint-disable-line import/no-unresolved

import ResetViewsButton from './ResetViewsButton';
export const ExamIcon = BookIcon;

const useQuickFilterStyles = makeStyles(theme => ({
    chip: {
        marginBottom: theme.spacing(1),
    },
}));
const QuickFilter = ({ label }) => {
    const translate = useTranslate();
    const classes = useQuickFilterStyles();
    return <Chip className={classes.chip} label={translate(label)} />;
};

const ExamFilter = props => (
    <Filter {...props}>
        <TextInput alwaysOn
            source="patient"
           
        />
        <DateInput alwaysOn
            source="date"
        />
        <BooleanInput
            source="diagnosed"
        />
    </Filter>
);



const exporter = exams => {
    const data = exams.map(exam => ({
        ...exam,
    }));
    jsonExport(data, (err, csv) => downloadCSV(csv, 'exams'));
};

const useStyles = makeStyles(theme => ({
    title: {
        maxWidth: '20em',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    hiddenOnSmallScreens: {
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    publishedAt: { fontStyle: 'italic' },
}));

const ExamListBulkActions = memo(props => (
    <Fragment>
        
        <BulkExportButton {...props} />
    </Fragment>
));

const useExamListActionToolbarStyles = makeStyles({
    toolbar: {
        alignItems: 'center',
        display: 'flex',
        marginTop: -1,
        marginBottom: -1,
    },
});

const ExamListActionToolbar = ({ children, ...props }) => {
    const classes = useExamListActionToolbarStyles();
    return (
        <div className={classes.toolbar}>
            {Children.map(children, button => cloneElement(button, props))}
        </div>
    );
};

const rowClick = (id, basePath, record) => {
    if (record.diagnosed) {
        return 'edit';
    }

    return 'edit';
};

const ExamPanel = ({ id, record, resource }) => (
    <div dangerouslySetInnerHTML={{ __html: record.diagnosis }} />
);

const ExamList = props => {
    const classes = useStyles();
    const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List
            {...props}
            bulkActionButtons={<ExamListBulkActions />}
            filters={<ExamFilter />}
            sort={{ field: 'created_at', order: 'DESC' }}
            exporter={exporter}
        >
            {isXSmall ? (
                <SimpleList
                    primaryText={record => record.patient}
                    secondaryText={record => record.lesion}
                    tertiaryText={record =>
                        new Date(record.date).toLocaleDateString()
                    }
                />
                ) : isSmall ? (
                    <Datagrid rowClick={rowClick} expand={false} optimized>
              
                     <TextField source="id" />
                <FunctionField label="Patient" render={
                    record =>  <>#{record.patient.id} ({record.patient.birthday_year})</>
                } />   
                <FunctionField label="Lesion" render={
                    record =>  <>#{record.lesion.id} ({record.lesion.site})</>
                } />   
                <FunctionField label="Diagnosis" render={
                    record => record.diagnosis.three ? <>{record.diagnosis.one}<br/>{record.diagnosis.two}<br/><span style={{fontWeight: 'bold'}}>{record.diagnosis.three}</span></> : <i>Pending</i>
                } />
            <DateField source="date" />

                </Datagrid>
            ) : (
                <Datagrid rowClick={rowClick} expand={false} optimized>
              
              <TextField source="id" />
                <FunctionField label="Patient" render={
                    record =>  <>#{record.patient.id} ({record.patient.birthday_year})</>
                } />   
                <FunctionField label="Lesion" render={
                    record =>  <>#{record.lesion.id} ({record.lesion.site})</>
                } />   
                <FunctionField label="Diagnosis" render={
                    record => record.diagnosis.three ? <>{record.diagnosis.one}<br/>{record.diagnosis.two}<br/><span style={{fontWeight: 'bold'}}>{record.diagnosis.three}</span></> : <i>Pending</i>
                } />
            <DateField source="date" />
           
                    <ExamListActionToolbar>
{/*                        <EditButton /> */}
                    </ExamListActionToolbar>
                </Datagrid>
            )}
        </List>
    );
};

export default ExamList;
