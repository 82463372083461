/* eslint react/jsx-key: off */
import memoize from 'lodash/memoize';
import { useMediaQuery } from '@material-ui/core';
import React from 'react';
import {
    BulkDeleteWithConfirmButton,
    Datagrid,
    Filter,
    List,
    SearchInput,
    SimpleList,
    TextField,
    EmailField,
    DateField,
    ArrayField,
    SingleFieldList,
    ChipField,
        TextInput,
} from 'react-admin';


const SpectraList = props => (
    <List {...props}>
        <Datagrid rowClick="show">
            <TextField source="id" />
            <TextField source="hash" />
            <DateField source="created_at" />
            <TextField source="event" />
            <TextField source="device" />
        </Datagrid>
    </List>
);

export default SpectraList;