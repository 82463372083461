import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew';
/*
import LesionCreate from './LesionCreate';
import LesionEdit from './LesionEdit';
import LesionList from './LesionList';
import LesionShow from './LesionShow';
*/
export default {
    //list: LesionList,
    //create: LesionCreate,
    //edit: LesionEdit,
    //show: LesionShow,
    icon: AccessibilityNewIcon,
};
