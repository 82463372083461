import CodeIcon from '@material-ui/icons/Code';


/*import SpectrumCreate from './SpectrumCreate';
import SpectrumEdit from './SpectrumEdit';
*/
import SpectrumList from './SpectrumList';
import SpectrumShow from './SpectrumShow';

export default {
    list: SpectrumList,
  /*  create: SpectrumCreate,
    edit: SpectrumEdit,
    */
    //show: SpectrumShow,
    icon: CodeIcon,
};
