import PeopleIcon from '@material-ui/icons/People';
import PatientCreate from './PatientCreate';
import PatientEdit from './PatientEdit';
import PatientList from './PatientList';
import PatientShow from './PatientShow';

export default {
    list: PatientList,
   //create: PatientCreate,
    //edit: PatientEdit,
    //show: PatientShow,
    icon: PeopleIcon,
};
