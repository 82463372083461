function years() {
    var currentYear = new Date().getFullYear(), years = [];
    var startYear = 1900;  
    while ( startYear <= currentYear ) {
        years.push(startYear++);
    }   
    return years.map((y) =>  {return {id: (typeof y==='string')?y:y.toString(), text: (typeof y==='string')?y:y.toString()} });
  }

  function extract_position(value) {
    const body_view = value.charAt(0)==='F' ? 'Front' : 'Rear';
    const second_char = value.charAt(1);
    if (second_char==='L')  
      return body_view + ' Left '+ value.substring(2);
    else if (second_char==='R')  
      return body_view + ' Right '+ value.substring(2);
    else
      return body_view + ' '+ value.substring(1);
  }

const SEX_CHOICES = [
    {id: 'm', text: 'male'},
    {id: 'f', text: 'female'},
  ];
  
  const PHOTOTYPE_CHOICES = [
    {id: 1, text: 'I'},
    {id: 2, text: 'II'},
    {id: 3, text: 'III'},
    {id: 4, text: 'IV'},
    {id: 5, text: 'V'},
    {id: 6, text: 'VI'},
  ]
  
  const BMI_CHOICES = [
    {id: 1, text: 'Below 18.5 - Underweight'},
    {id: 2, text: '18.5 – 24.9 - Healthy Weight'},
    {id: 3, text: '25.0 - 29.9 - Overweight'},
    {id: 4, text: '30.0 - 34.9 - Obesity Class I'},
    {id: 5, text: '35.0 - 39.9 - Obesity Class II'},
    {id: 6, text: 'Above 40 - Obesity Class III'},
  ]


  export {years,extract_position,SEX_CHOICES, PHOTOTYPE_CHOICES, BMI_CHOICES} ;