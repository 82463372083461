import * as React from 'react';
import { FC } from 'react';
import CameraEnhanceIcon from '@material-ui/icons/CameraEnhance';

import { useTranslate } from 'react-admin';
import { Link } from 'react-router-dom';
import Rating from '@material-ui/lab/Rating';

import CardWithIcon from './CardWithIcon';
import {
    Avatar,
    Box,
    Button,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Tooltip,
    CardMedia
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { format } from 'date-fns';

interface Props {
    value?: string;
}

const NewImages: FC<Props> = ({ images = [], nb }) => {
    const classes = useStyles();
    const translate = useTranslate();
    return (
        <CardWithIcon
            icon={CameraEnhanceIcon}
            title={translate('dashboard.pending_images')}
            subtitle={nb}
        >
            <List>
                {images.map((record: Review) => (
                    <ListItem
                        key={record.id}
                        button
                        component={Link}
                        to={`/wizard/${record.hash}`}
                        alignItems="flex-start"
                    >
                        <ListItemAvatar>
                            <Tooltip title={
                                <>
                                <span><img src={record.file} width={245} height={205}></img></span>
                                </>
                                
                            }>
                            <Avatar src={record.file} className={classes.avatar}/>
                            </Tooltip>
                        </ListItemAvatar>

                        <ListItemText
                            primary={<Rating name="read-only" value={1 + Math.random() * (5 - 1)} readOnly/> }
                            secondary={format(record.created_at, 'DD-MM-YYYY HH:mm')}
                            className={classes.listItemText}
                            style={{ paddingRight: 0 }}
                        />
                    </ListItem>
                ))}
            </List>
            <Box flexGrow="1">&nbsp;</Box>
            <Button
                className={classes.link}
                component={Link}
                to="/images"
                size="small"
                color="primary"
            >
                <Box p={1} className={classes.linkContent}>
                    {translate('dashboard.all_images')}
                </Box>
            </Button>
        </CardWithIcon>
    );
};

const useStyles = makeStyles(theme => ({
    avatar: {
        background: theme.palette.background.paper,
    },
    listItemText: {
        overflowY: 'hidden',
        height: '4em',
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
    },
    link: {
        borderRadius: 0,
    },
    linkContent: {
        color: theme.palette.primary.main,
    },
}));

export default NewImages;