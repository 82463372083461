import React, {useState} from "react";

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import Checkbox from '@material-ui/core/Checkbox';


import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import { extract_position } from "./helpers";

function Front({height, width, selected, setSelected, selectedLesion, setSelectedLesion, exams, mode}) {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const selectedColor = '#FFFF00';
  const lesionsColor = '#FFA500';

  let lesionsBySite = exams.reduce(function (r, a) {
   r[a.lesion.site] = r[a.lesion.site] || [];
   r[a.lesion.site].push(a);
   return r;
}, Object.create(null));
  
  function getFillBySite(site) {
   if (selectedLesion && selectedLesion?.site === site) return {fill: lesionsColor, fillOpacity: 1};
   if (site in lesionsBySite) {
      return {fill: selected === site ? selectedColor : lesionsColor, fillOpacity: selected === site ? 1 : 0.3};
   }
   else return selected === site ? {fill: selectedColor, fillOpacity: 1} : {fill: ''};
  }

  function getMacroByExam(exam) {
   try {
      return exam.images.filter((img => img.device === null))[0];
   } catch {
      return ''
   }
  }

  function handleClick(e) {
     //console.log(extract_position(e.currentTarget.id), lesionsBySite);
   if (extract_position(e.currentTarget.id) in lesionsBySite) {
     // setSelected(null) 
      setOpen(extract_position(e.currentTarget.id));
   } else {
      setSelectedLesion(null);
      if (selected===extract_position(e.currentTarget.id)) setSelected(null) 
      else setSelected(extract_position(e.currentTarget.id));
   }
  }

  const handleClose = () => {
   setOpen(false);
 };

 const handleListItemClick = (lesion) => {
    console.log(lesion, typeof lesion)
   if (typeof lesion !== 'string') {
      console.log('1a')
      setSelectedLesion(lesion);
      //setSelected(null);
      console.log('1b')
   } else {
      console.log('2a')
      setSelected(lesion);
      //setSelectedLesion(null);
      console.log('2b')
   }
   setOpen(false);
 };

  //console.log(selected, selectedLesion)

  return ( 
    <>
     <Dialog
        fullWidth={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
        Choose a lesion already inserted{!mode && ' or Create a new one'}
        </DialogTitle>
        <List>
        {exams.filter((e) => e.lesion.site===open).map((e) => (
          <ListItem button selected={e.lesion.id===selectedLesion?.id} onClick={() => handleListItemClick(mode ? e.id : e.lesion)} key={e.lesion.id}>
          <ListItemAvatar>
              <img width="300" src={getMacroByExam(e)?.file} />
            </ListItemAvatar>
            <ListItemText id={e.lesion.id} style={{padding: 10}} primary={'Lesion # '+e.lesion.id + (getMacroByExam(e) && ' ('+new Date(getMacroByExam(e)?.created_at).toLocaleDateString()+')')} secondary={e.lesion.site}/>
            <ListItemSecondaryAction>
              <Checkbox
                edge="end"
                checked={e.lesion.id===selectedLesion?.id}
                inputProps={{ 'aria-labelledby': e.lesion.id }}
              />
            </ListItemSecondaryAction>
          </ListItem>
        ))}
{!mode &&
        <ListItem autoFocus button onClick={() => handleListItemClick(open)}>
        <ListItemAvatar>
            </ListItemAvatar>
          <ListItemText primary="Create new lesion" secondary={open} />
        </ListItem>
}
      </List>
      </Dialog>
  <svg
   xmlns="http://www.w3.org/2000/svg"
   height={height}
   width={width}
   id="Front"
   viewBox={"0 0 "+height+" "+width}
   y="0px"
   x="0px"
   version="1.1" 
   style={{cursor: 'pointer', fill: "#009FE5", stroke: '#FFFFFF', transform: 'scale(1.8)', marginTop: 150,
   strokeWidth: 2.8346, strokeMiterlimit:10, ":hover": {
    background: "#efefef"
  }}}>
<g onClick={handleClick} 
   transform="translate(-255.93945,-600.9827)"
   id="FHead" style={getFillBySite('Front Head')}>
	<path
   id="path12"
   d="m 458.2,398 c -5.5,-0.9 -8.9,-3 -11,-4.7 -1,-0.8 -3.4,-3.4 -8,-8.7 -5.6,-6.4 -5.9,-6 -7.3,-8 -2.8,-3.8 -3.2,-6.9 -5,-18.3 -0.3,-1.7 -0.5,-3.2 -0.7,-4.6 l 0.3,2.2 c -1.6,2.4 -2.7,2.9 -3.4,2.8 -1.9,-0.2 -1.7,-3.9 -4.8,-12.5 -1.5,-4.2 -2.7,-6.7 -1.8,-10 0.2,-0.7 0.8,-2.7 2.1,-3 1.4,-0.4 3.5,1.3 4.8,4.2 l 0.3,1.8 c -0.3,-2.1 -0.6,-4 -0.9,-6.8 -1.4,-11.9 -1.3,-14.8 -1,-17.3 0.5,-4.1 1.2,-9.9 5,-16 4.7,-7.7 11.4,-11.3 14.7,-13 6.5,-3.4 12.7,-4.4 16.7,-4.7 0.2,0 0.3,0 0.5,0 2.7,0.2 9.2,1 16.2,4.6 3.2,1.7 10.1,5.3 14.7,13 3.9,6.5 4.7,13.6 5,16 0,0.3 0.1,0.8 0.1,1.4 0.3,3.8 0,6.1 -1.1,15.9 -0.1,0.9 -0.3,2.6 -0.6,4.9 0,0.3 -0.3,2 -0.3,2 0,0 0,-1 0.3,-1.8 0.6,-2.3 3.1,-4.7 4.8,-4.2 1.4,0.4 1.9,2.3 2.1,3 1,3.3 -0.3,5.8 -1.8,10 -3.1,8.7 -2.8,12.2 -4.8,12.5 -1.3,0.2 -3.1,-1.3 -3.4,-2.8 -0.2,-1.1 0.3,-2.2 0.3,-2.2 0,0 -0.1,0.5 -0.7,4.6 -0.2,1.1 -2,13.1 -3.6,16 -0.6,1.2 -1.4,2.3 -1.4,2.3 -0.1,0.1 -0.3,0.5 -0.7,0.9 -1,1.2 -1.9,2 -2.2,2.2 -1.1,1 -2,2.1 -4.5,4.9 -4.8,5.4 -7.2,8.1 -8,8.7 -1.5,1.1 -5.3,3.6 -10.6,4.6 -0.1,0.1 -0.2,0.1 -0.3,0.1 z"
   className="st0" />
</g>
<g onClick={handleClick} 
   transform="translate(-255.93945,-600.9827)"
   id="FNeck" style={getFillBySite('Front Neck')}>
	<path
   id="path15"
   d="m 458.2,507.2 c 2.8,-0.2 5.7,-0.3 8.5,-0.5 -1,-22 1,-39 3,-50.5 2.6,-15 6.5,-30.5 6.5,-30.5 2.5,-10.1 4,-14.4 5.5,-22.5 2.1,-11.1 2.7,-20.3 2.8,-26.5 -0.7,0.8 -1.7,1.9 -3,3.3 -3.1,3.4 -3.5,3.7 -4.3,4.7 -3.2,3.7 -2.8,3.6 -4.6,5.4 -1.4,1.4 -3.5,3.5 -6.7,5.3 -1.6,0.9 -4.2,2.1 -7.8,2.6 -0.1,0 -0.2,0 -0.3,-0.1 -1.8,-0.3 -3.1,-0.5 -4.4,-1.2 -0.4,-0.2 -2.3,-1.2 -3,-1.4 0,0 0,0 0,0 -2,-0.5 -3.8,-2.3 -6.7,-5.3 -0.2,-0.2 -0.5,-0.6 -1,-1.1 -0.7,-0.8 -1.1,-1.4 -1.3,-1.7 -0.5,-0.7 -1.1,-1.4 -2.3,-2.7 -2.1,-2.4 -2.6,-2.8 -4.3,-4.7 -2.6,-3 -2.7,-3.4 -3,-3.3 -1.6,0.4 0.2,13.5 2.8,26.5 1.9,9.3 3.3,13.5 5.5,22.5 0,0 3.9,15.5 6.5,30.5 2,11.5 4,28.5 3,50.5 0.5,0 7.6,0.4 8.2,0.5 0,0.2 0.4,0.2 0.4,0.2 z"
   class="st0" />
</g>
<g onClick={handleClick} 
   transform="translate(-255.93945,-600.9827)"
   id="FLShoulder" style={getFillBySite('Front Left Shoulder')}>
	<path
   id="path18"
   d="m 496.9,404 c 4.1,3.5 7.6,5.4 14.6,9.2 6.1,3.3 10,5.4 15.7,7.5 8.9,3.4 12.6,3.2 20.4,7.5 1.5,0.8 3.9,2.2 6.6,4.2 11.5,8.7 15.9,20.8 17,24 2.9,8.3 3.2,15.7 3.3,21.3 0.1,2 0,3.6 0,5 -0.2,3.8 -0.5,5.3 -0.7,8.6 -0.1,1.6 -0.1,3.9 0,6.7 -11.9,1.9 -23.8,3.8 -35.7,5.7 -0.3,-3.6 -0.6,-9 -0.7,-15.7 -0.1,-11.7 0.7,-12.6 0.2,-16.3 -2,-14.2 -17.2,-23.5 -21.5,-26 -8.9,-5.3 -14.4,-4.5 -28.5,-12.5 -5.1,-2.9 -9,-5.6 -11.5,-7.5 1.7,-5.1 3.9,-12.9 5.5,-22.5 2.2,-13.4 2.4,-26.5 2.8,-26.5 0.3,0 -1.2,7.3 1.9,14.8 2.5,5.8 6.7,9.2 10.6,12.5 z"
   class="st0" />
</g>
<g onClick={handleClick} 
   transform="translate(-255.93945,-600.9827)"
   id="FLArm" style={getFillBySite('Front Left Arm')}>
	<path
   id="path21"
   d="m 543.9,582 c -0.3,-0.4 -0.1,-0.4 -1.4,-8.1 -0.2,-1.4 -0.5,-2.8 -0.8,-4.6 0,0 -0.4,-2.3 -1,-4.7 -0.7,-3.2 -2.6,-10.3 -5.4,-19.6 0.6,-2.6 1.1,-5.4 1.6,-8.4 1.2,-7.6 1.4,-13.5 1.5,-17.8 0.1,-4.1 0.1,-9.2 -0.2,-15.2 11.9,-1.9 23.8,-3.8 35.7,-5.7 0.1,1.1 0.8,11.8 2.3,18.7 0.6,2.5 1,4.3 1,4.3 0.9,3.2 1.2,3.8 2,6.6 0.1,0.5 0.6,2.3 1,4.7 0.4,2.2 0.6,3.9 0.6,4.6 0.3,3.1 0.1,3.4 0.4,6.3 0.2,1.7 0.4,3.1 0.6,3.8 0.3,1.4 0.6,2.6 0.8,3.3 0.6,2.1 1.1,3.7 1.7,5.2 2.4,6.6 2.5,6.5 2.6,6.9 1.5,8.6 -38.9,26 -43,19.7 z"
   class="st0" />
</g>
<g onClick={handleClick} 
   transform="translate(-255.93945,-600.9827)"
   id="FLForearm" style={getFillBySite('Front Left Forearm')}>
	<path
   id="path24"
   d="m 612.5,683.4 c -2.3,-11.1 -4.2,-19.7 -5.7,-25.7 -0.9,-3.6 -2.9,-11.9 -4.3,-21 -2,-12.6 -1.1,-13.5 -3,-27 -2.1,-14.6 -5.3,-26.3 -5.3,-26.3 -1.6,-5.6 -3.9,-12.8 -7.3,-21 -0.1,0.7 -0.3,1.8 -0.8,3 -0.9,1.7 -2.2,2.7 -3.4,3.6 -3.3,2.4 -4.9,3.5 -6.4,4.4 -7,3.9 -12.8,6.1 -15.5,7.1 0,0 -2.5,0.9 -6.5,2 0,0 -1.4,0.4 -5.6,0.9 -0.7,0.1 -1.7,0.2 -2.8,-0.2 -0.9,-0.3 -1.6,-0.8 -2,-1.2 1.2,7.9 3.3,17.9 7.4,29.3 0.1,0.3 0.4,1 0.8,2.1 4.8,12.8 9.2,20.1 13.8,29 6.2,11.9 9.3,17.8 10.6,22.8 0,0 4.2,15.9 8.7,23.8 0,0 0.2,0.3 0.4,0.7 0.7,1.4 1.2,2.7 1.5,3.7 0.4,-1.2 1.3,-3 2.8,-4.7 2.2,-2.4 4.6,-3.3 7.1,-4.3 5.6,-2.2 10.4,-1.8 11.7,-1.7 1.5,0.1 2.9,0.4 3.8,0.7 z"
   class="st0" />
</g>
<g onClick={handleClick} 
   transform="translate(-255.93945,-600.9827)"
   id="FLHand" style={getFillBySite('Front Left Hand')}>
	<path
   id="path27"
   d="m 586.9,693.4 c -0.1,0.7 -0.1,0.9 0.7,5.4 2.4,14.4 1.9,12.4 2.6,15.7 1.7,8.5 2.3,9 2.9,14.3 0.2,1.3 0.5,4.7 0.6,9.1 0.2,19.7 0.1,24.3 0.1,24.3 0,0.6 -0.1,2.1 0.9,3.1 0.1,0.1 1,0.9 2.2,0.8 1.2,-0.1 2,-1.1 2.6,-1.8 1.4,-1.9 1.7,-4 1.8,-4.4 0.6,-5 0.2,-12 0.1,-16.4 -0.1,-2.1 -0.2,-5.2 1.4,-8.5 0.3,-0.7 0.7,-1.3 0.9,-1.7 1.2,8.6 1.7,15.6 1.9,20.2 0,0 0.8,15.2 5.2,21.2 0.4,0.5 1.4,1.9 2.8,1.9 1.1,0 1.9,-0.9 2.2,-1.2 0.2,-0.3 1.1,-1.2 1.3,-3.9 0.2,-1.9 -0.1,-3.4 -0.2,-3.8 -0.4,-2.6 -0.6,-5.2 -1,-7.8 -0.4,-2.1 -0.6,-5.3 -1,-11.6 -0.2,-3.6 -0.5,-8.1 -0.7,-13.3 3.5,8.5 6.1,15.2 7.9,19.8 0.6,1.5 1.2,3 1.8,4.5 2.6,6.9 3.9,12.4 7.4,16.4 0.6,0.7 1.7,1.8 3.2,1.9 1.2,0.1 2.5,-0.4 3.3,-1.3 1.2,-1.5 0.7,-3.7 0,-6.7 -0.7,-3.1 -1.7,-5 -1.7,-5 -2.2,-4.6 -3.3,-9.6 -5,-14.3 -1.5,-4.2 -3.4,-10.4 -5.3,-18.7 2.9,5.8 6.1,12 9.7,18.7 4.8,9 -0.2,-0.8 8.3,12.7 1.8,2.9 3.4,5.5 5.3,5.3 1.5,-0.2 2.6,-2 3,-3.3 0.9,-2.8 -0.7,-5.6 -1.3,-6.7 -3.2,-5.6 -5,-11.9 -8,-17.7 -4.3,-8.2 -6.4,-12.4 -7.7,-16.7 -1,-3.3 -1.4,-6.1 -1.7,-8 1.7,2.3 4.6,5.4 9,7.7 2.2,1.1 5.8,2.9 10.3,2.3 1.7,-0.2 5.1,-0.6 6,-3 0.6,-1.6 -0.1,-3.6 -1.3,-4.7 -1.6,-1.5 -3.3,-0.8 -6,-1.3 -3.3,-0.7 -5.3,-2.7 -8.3,-5.7 -3.6,-3.6 -3.7,-5.1 -7,-9.3 -3.8,-4.9 -7.7,-7.9 -11.7,-11 -5.8,-4.5 -8.7,-6.7 -12,-7.7 -10.3,-2.8 -24,2.9 -25.5,10.2 z"
   class="st0" />
</g>
<g onClick={handleClick} 
   transform="translate(-255.93945,-600.9827)"
   id="FRShoulder" style={getFillBySite('Front Right Shoulder')}>
	<path
   id="path30"
   d="m 419.5,404 c -4.1,3.5 -7.6,5.4 -14.6,9.2 -6.1,3.3 -10,5.4 -15.7,7.5 -8.9,3.4 -12.6,3.2 -20.4,7.5 -1.5,0.8 -3.9,2.2 -6.6,4.2 -11.5,8.7 -15.9,20.8 -17,24 -2.9,8.3 -3.2,15.7 -3.3,21.3 -0.1,2 0,3.6 0,5 0.2,3.8 0.5,5.3 0.7,8.6 0.1,1.6 0.1,3.9 0,6.7 11.9,1.9 23.8,3.8 35.7,5.7 0.3,-3.6 0.6,-9 0.7,-15.7 0.1,-11.7 -0.7,-12.6 -0.2,-16.3 2,-14.2 17.2,-23.5 21.5,-26 8.9,-5.3 14.4,-4.5 28.5,-12.5 5.1,-2.9 9,-5.6 11.5,-7.5 -1.7,-5.1 -3.9,-12.9 -5.5,-22.5 -2.2,-13.4 -2.4,-26.5 -2.8,-26.5 -0.3,0 1.2,7.3 -1.9,14.8 -2.5,5.8 -6.7,9.2 -10.6,12.5 z"
   class="st0" />
</g>
<g onClick={handleClick} 
   transform="translate(-255.93945,-600.9827)"
   id="FRArm" style={getFillBySite('Front Right Arm')}>
	<path
   id="path33"
   d="m 372.5,582 c 0.3,-0.4 0.1,-0.4 1.4,-8.1 0.2,-1.4 0.5,-2.8 0.8,-4.6 0,0 0.4,-2.3 1,-4.7 0.7,-3.2 2.6,-10.3 5.4,-19.6 -0.6,-2.6 -1.1,-5.4 -1.6,-8.4 -1.2,-7.6 -1.4,-13.5 -1.5,-17.8 -0.1,-4.1 -0.1,-9.2 0.2,-15.2 -11.9,-1.9 -23.8,-3.8 -35.7,-5.7 -0.1,1.1 -0.8,11.8 -2.3,18.7 -0.6,2.5 -1,4.3 -1,4.3 -0.9,3.2 -1.2,3.8 -2,6.6 -0.1,0.5 -0.6,2.3 -1,4.7 -0.4,2.2 -0.6,3.9 -0.6,4.6 -0.3,3.1 -0.1,3.4 -0.4,6.3 -0.2,1.7 -0.4,3.1 -0.6,3.8 -0.3,1.4 -0.6,2.6 -0.8,3.3 -0.6,2.1 -1.1,3.7 -1.7,5.2 -2.4,6.6 -2.5,6.5 -2.6,6.9 -1.5,8.6 38.8,26 43,19.7 z"
   class="st0" />
</g>
<g onClick={handleClick} 
   transform="translate(-255.93945,-600.9827)"
   id="FRForearm" style={getFillBySite('Front Right Forearm')}>
	<path
   id="path36"
   d="m 303.9,683.4 c 2.3,-11.1 4.2,-19.7 5.7,-25.7 0.9,-3.6 2.9,-11.9 4.3,-21 2,-12.6 1.1,-13.5 3,-27 2.1,-14.6 5.3,-26.3 5.3,-26.3 1.6,-5.6 3.9,-12.8 7.3,-21 0.1,0.7 0.3,1.8 0.8,3 0.9,1.7 2.2,2.7 3.4,3.6 3.3,2.4 4.9,3.5 6.4,4.4 7,3.9 12.8,6.1 15.5,7.1 0,0 2.5,0.9 6.5,2 0,0 1.4,0.4 5.6,0.9 0.7,0.1 1.7,0.2 2.8,-0.2 0.9,-0.3 1.6,-0.8 2,-1.2 -1.2,7.9 -3.3,17.9 -7.4,29.3 -0.1,0.3 -0.4,1 -0.8,2.1 -4.8,12.8 -9.2,20.1 -13.8,29 -6.2,11.9 -9.3,17.8 -10.6,22.8 0,0 -4.2,15.9 -8.7,23.8 0,0 -0.2,0.3 -0.4,0.7 -0.7,1.4 -1.2,2.7 -1.5,3.7 -0.4,-1.2 -1.3,-3 -2.8,-4.7 -2.2,-2.4 -4.6,-3.3 -7.1,-4.3 -5.6,-2.2 -10.4,-1.8 -11.7,-1.7 -1.5,0.1 -2.9,0.4 -3.8,0.7 z"
   class="st0" />
</g>
<g onClick={handleClick} 
   transform="translate(-255.93945,-600.9827)"
   id="FRHand" style={getFillBySite('Front Right Hand')}>
	<path
   id="path39"
   d="m 329.4,693.4 c 0.1,0.7 0.1,0.9 -0.7,5.4 -2.4,14.4 -1.9,12.4 -2.6,15.7 -1.7,8.5 -2.3,9 -2.9,14.3 -0.2,1.3 -0.5,4.7 -0.6,9.1 -0.2,19.7 -0.1,24.3 -0.1,24.3 0,0.6 0.1,2.1 -0.9,3.1 -0.1,0.1 -1,0.9 -2.2,0.8 -1.2,-0.1 -2,-1.1 -2.6,-1.8 -1.4,-1.9 -1.7,-4 -1.8,-4.4 -0.6,-5 -0.2,-12 -0.1,-16.4 0.1,-2.1 0.2,-5.2 -1.4,-8.5 -0.3,-0.7 -0.7,-1.3 -0.9,-1.7 -1.2,8.6 -1.7,15.6 -1.9,20.2 0,0 -0.8,15.2 -5.2,21.2 -0.4,0.5 -1.4,1.9 -2.8,1.9 -1.1,0 -1.9,-0.9 -2.2,-1.2 -0.2,-0.3 -1.1,-1.2 -1.3,-3.9 -0.2,-1.9 0.1,-3.4 0.2,-3.8 0.4,-2.6 0.6,-5.2 1,-7.8 0.4,-2.1 0.6,-5.3 1,-11.6 0.2,-3.6 0.5,-8.1 0.7,-13.3 -3.5,8.5 -6.1,15.2 -7.9,19.8 -0.6,1.5 -1.2,3 -1.8,4.5 -2.6,6.9 -3.9,12.4 -7.4,16.4 -0.6,0.7 -1.7,1.8 -3.2,1.9 -1.2,0.1 -2.5,-0.4 -3.3,-1.3 -1.2,-1.5 -0.7,-3.7 0,-6.7 0.7,-3.1 1.7,-5 1.7,-5 2.2,-4.6 3.3,-9.6 5,-14.3 1.5,-4.2 3.4,-10.4 5.3,-18.7 -2.9,5.8 -6.1,12 -9.7,18.7 -4.8,9 0.2,-0.8 -8.3,12.7 -1.8,2.9 -3.4,5.5 -5.3,5.3 -1.5,-0.2 -2.6,-2 -3,-3.3 -0.9,-2.8 0.7,-5.6 1.3,-6.7 3.2,-5.6 5,-11.9 8,-17.7 4.3,-8.2 6.4,-12.4 7.7,-16.7 1,-3.3 1.4,-6.1 1.7,-8 -1.7,2.3 -4.6,5.4 -9,7.7 -2.2,1.1 -5.8,2.9 -10.3,2.3 -1.7,-0.2 -5.1,-0.6 -6,-3 -0.6,-1.6 0.1,-3.6 1.3,-4.7 1.6,-1.5 3.3,-0.8 6,-1.3 3.3,-0.7 5.3,-2.7 8.3,-5.7 3.6,-3.6 3.7,-5.1 7,-9.3 3.8,-4.9 7.7,-7.9 11.7,-11 5.8,-4.5 8.7,-6.7 12,-7.7 10.4,-2.8 24,2.9 25.5,10.2 z"
   class="st0" />
</g>
<g onClick={handleClick} 
   transform="translate(-255.93945,-600.9827)"
   id="FLChest" style={getFillBySite('Front Left Chest')}>
	<path
   id="path42"
   d="m 487.7,433.2 c 2.1,1.1 5.3,2.8 9.3,4.7 11.4,5.3 14,5.2 19.2,7.8 8.6,4.4 19.5,14 21.5,26 0.7,3.9 -0.5,3.1 -0.2,16.3 0.1,5.8 0.4,7.1 0.7,15.7 0.2,7.2 0.4,10.8 0.2,15.2 -0.2,9.5 -1.2,15.9 -1.5,17.8 -0.5,3.5 -1.1,6.3 -1.6,8.4 -4.3,0.6 -11,1.7 -19.1,3.1 -16.7,3 -22.7,4.8 -34.5,6.5 -5.5,0.8 -13.6,1.7 -23.5,2 0,-16.5 0,-33 0,-49.5 2.8,-0.2 5.7,-0.3 8.5,-0.5 -0.5,-14.5 0.1,-31.6 3,-50.5 1.7,-11.1 4,-21.3 6.5,-30.5 0,0 0,0 0,0 z"
   class="st0" />
</g>
<g onClick={handleClick} 
   transform="translate(-255.93945,-600.9827)"
   id="FLWaist" style={getFillBySite('Front Left Waist')}>
	<path
   id="path45"
   d="m 458.2,556.7 c 9.9,-0.3 18,-1.2 23.5,-2 11.8,-1.7 17.8,-3.5 34.5,-6.5 l 19.1,-3.1 c -1.5,3.3 -3.6,8.3 -5.4,14.5 -2.3,8 -6.6,23 -3.1,39.5 1.2,5.6 2.7,9.1 3.4,17.1 0.5,5.9 -0.1,7.3 0.6,12.8 -12.8,0.6 -25.5,1.2 -38.3,1.8 -11.4,0.5 -22.9,1.1 -34.3,1.7 0,-25.4 0,-50.6 0,-75.8 z"
   class="st0" />
</g>
<g onClick={handleClick} 
   transform="translate(-255.93945,-600.9827)"
   id="FLGroin" style={getFillBySite('Front Left Groin')}>
	<path
   id="path48"
   d="m 458.2,632.4 c 11.4,-0.6 22.9,-1.1 34.3,-1.7 l 38.3,-1.8 c 0.6,4.6 1.2,5.5 4.3,16.5 4.4,15.7 4.7,19.6 4.8,21.9 0.2,4.8 -0.4,5.2 0,10.2 0.6,8.2 2.5,14.1 3.1,15.9 l -33.5,5.9 c -8.1,1.4 -25.5,5.7 -38.2,20.7 -2.7,3.2 -4.8,6.4 -6.4,9.5 -2.2,-0.1 -4.5,-0.2 -6.8,-0.3 0.1,-32.3 0.1,-64.5 0.1,-96.8 z"
   class="st0" />
</g>
<g onClick={handleClick} 
   transform="translate(-255.93945,-600.9827)"
   id="FRChest" style={getFillBySite('Front Right Chest')}>
	<path
   id="path51"
   d="m 428.7,433.2 c -2.1,1.1 -5.3,2.8 -9.3,4.7 -11.4,5.3 -14,5.2 -19.2,7.8 -8.6,4.4 -19.5,14 -21.5,26 -0.7,3.9 0.5,3.1 0.2,16.3 -0.1,5.8 -0.4,7.1 -0.7,15.7 -0.2,7.2 -0.4,10.8 -0.2,15.2 0.2,9.5 1.2,15.9 1.5,17.8 0.5,3.5 1.1,6.3 1.6,8.4 4.3,0.6 11,1.7 19.1,3.1 16.7,3 22.7,4.8 34.5,6.5 5.5,0.8 13.6,1.7 23.5,2 0,-16.5 0,-33 0,-49.5 -2.8,-0.2 -5.7,-0.3 -8.5,-0.5 0.5,-14.5 -0.1,-31.6 -3,-50.5 -1.7,-11.1 -4,-21.3 -6.5,-30.5 0,0 0,0 0,0 z"
   class="st0" />
</g>
<g onClick={handleClick} 
   transform="translate(-255.93945,-600.9827)"
   id="FRWaist" style={getFillBySite('Front Right Waist')}>
	<path
   id="path54"
   d="m 458.2,556.7 c -9.9,-0.3 -18,-1.2 -23.5,-2 -11.8,-1.7 -17.8,-3.5 -34.5,-6.5 l -19.1,-3.1 c 1.5,3.3 3.6,8.3 5.4,14.5 2.3,8 6.6,23 3.1,39.5 -1.2,5.6 -2.7,9.1 -3.4,17.1 -0.5,5.9 0.1,7.3 -0.6,12.8 12.8,0.6 25.5,1.2 38.3,1.8 11.4,0.5 22.9,1.1 34.3,1.7 0,-25.4 0,-50.6 0,-75.8 z"
   class="st0" />
</g>
<g onClick={handleClick} 
   transform="translate(-255.93945,-600.9827)"
   id="FRGroin" style={getFillBySite('Front Right Groin')}>
	<path
   id="path57"
   d="m 458.2,632.4 c -11.4,-0.6 -22.9,-1.1 -34.3,-1.7 l -38.3,-1.8 c -0.6,4.6 -1.2,5.5 -4.3,16.5 -4.4,15.7 -4.7,19.6 -4.8,21.9 -0.2,4.8 0.4,5.2 0,10.2 -0.6,8.2 -2.5,14.1 -3.1,15.9 l 33.5,5.9 c 8.1,1.4 25.5,5.7 38.2,20.7 2.7,3.2 4.8,6.4 6.4,9.5 2.2,-0.1 4.5,-0.2 6.8,-0.3 -0.1,-32.3 -0.1,-64.5 -0.1,-96.8 z"
   class="st0" />
</g>
<g onClick={handleClick} 
   transform="translate(-255.93945,-600.9827)"
   id="FLLeg" style={getFillBySite('Front Left Leg')}>
	<path
   id="path60"
   d="m 476.8,904.3 c -0.1,-1.7 -0.4,-4.2 -0.8,-7.2 -1.2,-9.1 -2.8,-15.7 -3.3,-18.4 -1.6,-9.1 -2.2,-15.5 -3.3,-27.2 -0.6,-6.2 -1.2,-14.1 -1.9,-34.3 -0.9,-24.8 -0.7,-34.1 -1.5,-59.4 -0.4,-12.3 -0.8,-22.2 -1,-28.2 0.7,-1.4 1.7,-3 2.8,-4.7 0,0 1.7,-2.5 3.6,-4.8 1.6,-1.8 13.9,-15 38.2,-20.7 0.2,0 0,0 27.5,-4.9 0.1,0 5.9,-1 6,-1.1 2.2,10.1 4.5,24.1 5.1,40.9 0.2,5.8 0.7,25.7 -3.7,51.7 -3.9,22.8 -8.1,30 -12.8,57.4 -1.7,10 -3,19.6 -3.7,25 -1.4,10.5 -1.8,15.2 -2,19 -0.4,6.5 -0.4,12 -0.3,15.9 -1.2,0.4 -2.9,0.9 -5,1.5 -9.7,2.7 -17.5,4.9 -25.8,4.5 -4.1,-0.1 -10.5,-1 -18.1,-5 z"
   class="st0" />
</g>
<g onClick={handleClick} 
   transform="translate(-255.93945,-600.9827)"
   id="FLShin" style={getFillBySite('Front Left Shin')}>
	<path
   id="path63"
   d="m 470.5,1076.3 c 0,-0.1 0.5,-1.8 1.3,-4.1 0,0 0.5,-1.7 1,-3.3 0,0 1.2,-4 2.3,-7.7 3.7,-13 2.3,-31.5 2.3,-31.5 -0.6,-7.5 2.3,30 -2.6,-59.4 -1,-18.1 -1.3,-22.8 -0.1,-30.6 0.6,-4 1.5,-8 2,-13.6 0.3,-3.3 0.4,-5.8 0.5,-6.7 0.2,-3.8 0.2,-9 -0.4,-15.1 2.7,1.5 6.9,3.4 12.4,4.4 6.5,1.2 11.6,0.6 15,0.2 4.8,-0.6 8.8,-1.8 16.5,-4 2.1,-0.6 3.8,-1.1 5,-1.5 0.3,4.4 1,16.6 1.7,23.9 0.1,0.6 0.4,3.9 0.8,8 0.3,3.5 0.6,6 0.6,6.2 1.5,14.9 -2,34.4 -2,34.4 -1.9,10.4 -2.9,11.3 -9.7,35.8 -10.1,36.4 -5.5,25.8 -10.2,42.9 0,0 -3.6,13.2 -2.3,24.7 0,0 0,0.2 0,0.3 0.3,2.6 0.1,4.8 0,6.2 -0.1,0.7 -0.3,2.4 -0.9,4.3 -0.2,0.7 -0.4,1.3 -0.5,1.4 -0.1,0.2 -0.4,1.1 -0.9,2.3 0,0 0,0 0,0 -0.7,1.6 0.1,7.6 0,7.7 -0.1,0 -1.1,-6.4 -4.7,-11.4 -0.4,-0.6 -7.7,-10.3 -17,-8 -7.8,1.9 -10.6,10.8 -10.7,11.4 -0.4,-10.8 0,-15.3 0.6,-17.2 z"
   class="st0" />
</g>
<g onClick={handleClick} 
   transform="translate(-255.93945,-600.9827)"
   id="FLFoot" style={getFillBySite('Front Left Foot')}>
	<path
   id="path66"
   d="m 470,1093.6 c 0.3,11.9 -2.3,13.7 -1.7,27.3 0.2,3.7 0.6,8.1 3.7,11.4 0.6,0.7 3.7,3.9 7.1,3.4 2.7,-0.4 3.4,-2.8 5.7,-2.6 2.3,0.3 3,3.1 4.8,2.8 1.7,-0.2 2,-2.6 3.4,-2.6 1.4,0.1 1.7,2.5 3.1,2.6 1.5,0.1 2,-2.3 3.7,-2.3 1.4,0 1.7,1.7 3.1,1.7 1.2,0 1.3,-1.1 2.8,-1.4 1.3,-0.3 1.6,0.4 2.8,0.3 2,-0.2 3.3,-2.3 3.7,-2.8 1.2,-1.8 1.2,-3.7 1.1,-4.8 0,-2.4 -0.8,-4.2 -2,-6.5 -0.7,-1.3 -1.2,-2.3 -1.8,-3.1 -1.8,-2.9 -3.1,-4.9 -3.6,-5.7 -1.3,-2 -2.8,-5 -3.7,-9.7 -0.4,-2.2 -0.6,-2.5 -1,-3.6 -0.4,-1.3 -3.2,-10.6 -11.5,-14.3 -1.7,-0.7 -5.4,-2.4 -9.8,-1.2 -7.3,1.9 -9.8,10.5 -9.9,11.1 z"
   class="st0" />
</g>
<g onClick={handleClick} 
   transform="translate(-255.93945,-600.9827)"
   id="FRLeg" style={getFillBySite('Front Right Leg')}>
	<path
   id="path69"
   d="m 439.6,904.3 c 0.1,-1.7 0.4,-4.2 0.8,-7.2 1.2,-9.1 2.8,-15.7 3.3,-18.4 1.6,-9.1 2.2,-15.5 3.3,-27.2 0.6,-6.2 1.2,-14.1 1.9,-34.3 0.9,-24.8 0.7,-34.1 1.5,-59.4 0.4,-12.3 0.8,-22.2 1,-28.2 -0.7,-1.4 -1.7,-3 -2.8,-4.7 0,0 -1.7,-2.5 -3.6,-4.8 -1.6,-1.8 -13.9,-15 -38.2,-20.7 -0.2,0 0,0 -27.5,-4.9 -0.1,0 -5.9,-1 -6,-1.1 -2.2,10.1 -4.5,24.1 -5.1,40.9 -0.2,5.8 -0.7,25.7 3.7,51.7 3.9,22.8 8.1,30 12.8,57.4 1.7,10 3,19.6 3.7,25 1.4,10.5 1.8,15.2 2,19 0.4,6.5 0.4,12 0.3,15.9 1.2,0.4 2.9,0.9 5,1.5 9.7,2.7 17.5,4.9 25.8,4.5 4.1,-0.1 10.5,-1 18.1,-5 z"
   class="st0" />
</g>
<g onClick={handleClick} 
   transform="translate(-255.93945,-600.9827)"
   id="FRShin" style={getFillBySite('Front Right Shin')}>
	<path
   id="path72"
   d="m 445.8,1076.3 c 0,-0.1 -0.5,-1.8 -1.3,-4.1 0,0 -0.5,-1.7 -1,-3.3 0,0 -1.2,-4 -2.3,-7.7 -3.8,-13 -2.3,-31.5 -2.3,-31.5 0.6,-7.5 -2.3,30 2.6,-59.4 1,-18.1 1.3,-22.8 0.1,-30.6 -0.6,-4 -1.5,-8 -2,-13.6 -0.3,-3.3 -0.4,-5.8 -0.5,-6.7 -0.2,-3.8 -0.2,-9 0.4,-15.1 -2.7,1.5 -6.9,3.4 -12.4,4.4 -6.5,1.2 -11.6,0.6 -15,0.2 -4.8,-0.6 -8.8,-1.8 -16.5,-4 -2.1,-0.6 -3.8,-1.1 -5,-1.5 -0.3,4.4 -1,16.6 -1.7,23.9 -0.1,0.6 -0.4,3.9 -0.8,8 -0.3,3.5 -0.6,6 -0.6,6.2 -1.5,14.9 2,34.4 2,34.4 1.9,10.4 2.9,11.3 9.7,35.8 10.1,36.4 5.5,25.8 10.2,42.9 0,0 3.6,13.2 2.3,24.7 0,0 0,0.2 0,0.3 -0.3,2.6 -0.1,4.8 0,6.2 0.1,0.7 0.3,2.4 0.9,4.3 0.2,0.7 0.4,1.3 0.5,1.4 0.1,0.2 0.4,1.1 0.9,2.3 0,0 0,0 0,0 0.7,1.6 -0.1,7.6 0,7.7 0.1,0 1.1,-6.4 4.7,-11.4 0.4,-0.6 7.7,-10.3 17,-8 7.8,1.9 10.6,10.8 10.7,11.4 0.5,-10.8 0,-15.3 -0.6,-17.2 z"
   class="st0" />
</g>
<g onClick={handleClick} 
   transform="translate(-255.93945,-600.9827)"
   id="FRFoot" style={getFillBySite('Front Right Foot')}>
	<path
   id="path75"
   d="m 446.4,1093.6 c -0.3,11.9 2.3,13.7 1.7,27.3 -0.2,3.7 -0.6,8.1 -3.7,11.4 -0.6,0.7 -3.7,3.9 -7.1,3.4 -2.7,-0.4 -3.4,-2.8 -5.7,-2.6 -2.3,0.3 -3,3.1 -4.8,2.8 -1.7,-0.2 -2,-2.6 -3.4,-2.6 -1.4,0.1 -1.7,2.5 -3.1,2.6 -1.5,0.1 -2,-2.3 -3.7,-2.3 -1.4,0 -1.7,1.7 -3.1,1.7 -1.2,0 -1.3,-1.1 -2.8,-1.4 -1.3,-0.3 -1.6,0.4 -2.8,0.3 -2,-0.2 -3.3,-2.3 -3.7,-2.8 -1.2,-1.8 -1.2,-3.7 -1.1,-4.8 0,-2.4 0.8,-4.2 2,-6.5 0.7,-1.3 1.2,-2.3 1.8,-3.1 1.8,-2.9 3.1,-4.9 3.6,-5.7 1.3,-2 2.8,-5 3.7,-9.7 0.4,-2.2 0.6,-2.5 1,-3.6 0.4,-1.3 3.2,-10.6 11.5,-14.3 1.7,-0.7 5.4,-2.4 9.8,-1.2 7.3,1.9 9.8,10.5 9.9,11.1 z"
   class="st0" />
</g>
</svg>
</>
);    
   
}

export default Front;