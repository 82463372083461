/* eslint react/jsx-key: off */
import React, {Component} from 'react';
import { Admin, Resource, ListGuesser, EditGuesser, ShowGuesser } from 'react-admin'; // eslint-disable-line import/no-unresolved
import { Route } from 'react-router-dom';

/*
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
*/
import { Dashboard } from './dashboard';

import drfProvider, { tokenAuthProvider, fetchJsonWithAuthToken } from './providers';

import i18nProvider from './i18nProvider';
import Layout from './Layout';
import MyMenu from './MyMenu';
import patients from './patients';
import exams from './exams';
import lesions from './lesions';
import images from './images';
import spectra from './spectra';
import results from './results';
import devices from './devices';
import tags from './tags';
import users from './users';

import Wizard from './wizard/Wizard';
import Diagnosis from './diagnosis/Diagnosis';

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
    dsn: "https://b6e2eb14118748268b2a2e40bbb8d874@o229283.ingest.sentry.io/5740931",
    environment: window.location.hostname,
    integrations: [new BrowserTracing()],
  
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
  

const authProvider = tokenAuthProvider();

const dataProvider = drfProvider("/api", fetchJsonWithAuthToken);
 class App extends Component {
    render() {
        return (
        <div>
    <Admin
        authProvider={authProvider}
        dataProvider={dataProvider}
        i18nProvider={i18nProvider}
        title="INPECOTEST Webapp"
        layout={Layout}
        menu={MyMenu}
        dashboard={Dashboard}
        disableTelemetry
        customRoutes={[
            <Route
            exact
            path="/wizard/:hash"
            component={props => <Wizard {...props} />}
            />,
            <Route
            exact
            path="/wizard"
            component={props => <Wizard {...props} />}
            />,
            <Route
            exact
            path="/diagnosis/:exam"
            component={props => <Diagnosis {...props} />}
            />,
            <Route
            exact
            path="/diagnosis"
            component={props => <Diagnosis {...props} />}
            />,
        ]}
    >
        {permissions => [
            <Resource name="patients" {...patients} options={{ label: 'Patients' }} />,
            <Resource name="exams" {...exams} options={{ label: 'Exams' }} /> ,
            permissions === 'admin' ? <Resource name="lesions" {...lesions} options={{ label: 'Lesions' }} list={ListGuesser} /> : null,
            <Resource name="images" {...images} options={{ label: 'Images' }} /> ,
            permissions === 'admin' ? <Resource name="spectra" {...spectra}  options={{ label: 'Spectra' }}  /> : null,
            permissions === 'admin' ? <Resource name="results" {...results}  options={{ label: 'Results' }} list={ListGuesser} /> : null,
            permissions === 'admin' ? <Resource name="devices" {...devices}  options={{ label: 'Devices' }} /> : null,
            permissions === 'admin' ? <Resource name="tags" {...tags}  options={{ label: 'Tags' }} /> : null,
            permissions === 'admin' ? <Resource name="users" {...users}  options={{ label: 'Users' }} list={ListGuesser} /> : null,
]}
    </Admin>
    </div>
    );
    }
}
    
export default App;
