import React, { useState } from 'react'
import {Create, SimpleForm, TextInput, DateInput, BooleanInput, ReferenceInput, SelectInput, NumberInput, required, minLength, maxLength} from 'react-admin';
import { Box } from '@material-ui/core';
import { RaGrid } from 'ra-compact-ui';

export default ({updateForm}) => {  

  return (
    <div>
        
        <SimpleForm toolbar={false} onSubmit={(event) => event.preventDefault()}>

        <RaGrid container spacing={1} style={{ width: "100%" }} >
          <RaGrid item xs={6}>
   
                    <BooleanInput source="infection"
                      label="Infection"
                      onChange={(value, event) => {
                        updateForm('infection', value)
                    }}/>
                    <BooleanInput source="blood" 
                      label="Blood"
                      onChange={(value, event) => {
                        updateForm('blood', value)
                    }}/>
                    </RaGrid>
                <RaGrid item xs={6}>
               
                
                    <BooleanInput source="itch" 
                      label="Itch"
                      onChange={(value, event) => {
                        updateForm('itch', value)
                     }}/>
                    <BooleanInput source="rapid_growth" 
                      label="Rapid growth"
                      onChange={(value, event) => {
                        updateForm('rapid_growth', value)
                    }}/>
                        
                    </RaGrid>
              

              <RaGrid item xs={12} style={{ width: "100%" }}>

              <BooleanInput source="evolution" label="Evolution" helperText="> 1 mm in 3 months (as registered by instrument)" 
                    onChange={(value, event) => {
                      updateForm('evolution', value)
                    }}/>
              </RaGrid>
              <RaGrid item xs={12}><br/><br/>
                <Box sx={{ fontStyle: 'italic' }}>
                  Ref: Tognetti et al., Dermatol. Pract. Concept., 2018 Tognetti et. al, JEADV, 2018, Tognetti et al, Ach. Dermatol. Res., 2021, Roffman et al., Sci. Rep., 2018
                </Box>
                </RaGrid>
              </RaGrid>
        </SimpleForm>

    </div>
  )
}