import React, { Children, Fragment, cloneElement, memo } from 'react';
import BookIcon from '@material-ui/icons/Book';
import Chip from '@material-ui/core/Chip';
import { useMediaQuery, makeStyles } from '@material-ui/core';
import lodashGet from 'lodash/get';
import jsonExport from 'jsonexport/dist';
import {
    BooleanField,
    BulkDeleteButton,
    BulkExportButton,
    ChipField,
    Datagrid,
    DateField,
    downloadCSV,
    EditButton,
    Filter,
    Edit,
    NumberField,
    ReferenceArrayField,
    SearchInput,
    DateInput,
    SimpleForm,
    SingleFieldList,
    RichTextField,
    NumberInput,
    TextInput,
    useTranslate,
} from 'react-admin';

const DeviceEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="id" />
            <TextInput source="title" />
            <NumberInput source="serial_number" />
            <DateInput source="created_at" />
            <TextInput source="user" />
        </SimpleForm>
    </Edit>
);

export default DeviceEdit;