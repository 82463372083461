import AssignmentIcon from '@material-ui/icons/Assignment';
import ExamCreate from './ExamCreate';
import ExamEdit from './ExamEdit';
import ExamList from './ExamList';
import ExamShow from './ExamShow';

export default {
    list: ExamList,
    //create: ExamCreate,
    //edit: ExamEdit,
    //show: ExamShow,
    icon: AssignmentIcon,
};
