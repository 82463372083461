import React, { useState } from 'react'

import { TextInput, SimpleForm, required, minLength, maxLength} from 'react-admin';
import { Box, IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ReplayIcon from '@material-ui/icons/Replay';
import './camera.css';

import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';

export const CircleButton = ({ onClick, isClicked }) => {
  const innerCircleClasses = isClicked ? 'is-clicked' : '';
  return (
    <div id="container-circles">
      <div
        id="outer-circle"
        onClick = {
          (e) => {
            if (!isClicked) {
              onClick();
            }
          }
        }
      >
        <div id="inner-circle" className={innerCircleClasses}>
          <ReplayIcon size="large" style={{width: '40px', height: '40px'}}/>
        </div>
      </div>
    </div>
  );
};

export const ImagePreview = ({ dataUri, handleTakePhotoAnimationDone, isFullscreen }) => {
  let classNameFullscreen = isFullscreen ? 'image-preview-fullscreen' : '';

  return (
    <div className={'image-preview ' + classNameFullscreen}>
      <img alt="macro" src={dataUri}/>
      <CircleButton isClicked={false} onClick={() => handleTakePhotoAnimationDone('')}/>
    </div>
  );
};

export default ({updateForm}) => {  
  const [dataUri, setDataUri] = useState('');

  function handleTakePhotoAnimationDone(dataUri) {
    console.log('takePhoto');
    setDataUri(dataUri);
    updateForm('macro_photo', dataUri);
  }
  const isFullscreen = false;

  return (
    <Box aria-label="macro" display="flex" sx={{marginBottom: 150, marginLeft: 10, marginRight: 10, flexWrap: 'wrap', flexDirection: 'row', justifyContent: 'center' }}>
    {
      (dataUri)
        ? <ImagePreview 
          style={{width: '100%', height: '100%', position: 'absolute', top: 0, left: 0}}
          dataUri={dataUri}
          handleTakePhotoAnimationDone={handleTakePhotoAnimationDone}
          isFullscreen={isFullscreen}
        />
        : <>
            <Camera 
              style={{width: '100%', height: '100%', position: 'absolute', top: 0, left: 0}}
              onTakePhotoAnimationDone = {handleTakePhotoAnimationDone}
              idealFacingMode = {FACING_MODES.ENVIRONMENT}
              isImageMirror = {false}
              isFullscreen={isFullscreen}
              ref={el => {
                // el can be null - see https://reactjs.org/docs/refs-and-the-dom.html#caveats-with-callback-refs
                if (!el) return;
        
                console.log(el.getBoundingClientRect().width); // prints 200px
              }}
            />
            <AddIcon fontSize="large" style={{color: 'yellow', position: 'absolute', top: `calc(50% - 12px)`, left: `calc(50% - 12px)`, zIndex: 9999}}/>
          </>
    }
    </Box>
  )
}