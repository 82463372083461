import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { Button } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { TextInput, SimpleForm, required, minLength, maxLength} from 'react-admin';

import { useFormState, useForm } from 'react-final-form';

import ButtonGroup from '@material-ui/core/ButtonGroup';


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2),
    height: 400,
    verticalAlign: 'middle',
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
}));


export default (props) => {
  const classes = useStyles();

  return (
    <div>
      <Paper className={classes.root}>
        <Typography variant="h5" component="h3">
        Diagnosis completed!
        </Typography>
        <Typography component="p"><br/>
          The diagnosis process is completed.
          <br/>              <br/>
          <ButtonGroup variant="contained" aria-label="outlined primary button group">

          <Button  
              variant="outlined" color="primary" className={classes.button} disabled onClick={props.firstStep}>
              Refine diagnosis
            </Button>
            <Button  
              variant="outlined" color="primary" className={classes.button} disabled href="/" target="_blank">
              Open Segmentation App
            </Button>
            <Button  
              variant="contained" color="primary" className={classes.button} href="/">
              Exit
            </Button>
            </ButtonGroup>
        </Typography>
      </Paper>

    </div>
  )
}