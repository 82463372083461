import * as React from 'react';
import { FC } from 'react';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import { useTranslate } from 'react-admin';

import CardWithIcon from './CardWithIcon';

interface Props {
    value?: string;
}

const NewPatients: FC<Props> = ({ value }) => {
    const translate = useTranslate();
    return (
        <CardWithIcon
            to="/patients"
            icon={GroupAddIcon}
            title={translate('dashboard.new_patients')}
            subtitle={value}
        />
    );
};

export default NewPatients;