import TagCreate from './TagCreate';
import TagEdit from './TagEdit';
import TagList from './TagList';
import TagShow from './TagShow';
import LabelIcon from '@material-ui/icons/Label';

export default {
    create: TagCreate,
    edit: TagEdit,
    list: TagList,
    show: TagShow,
    icon: LabelIcon
};
