import React, { useState } from 'react'

import {Create, SimpleForm, TextInput, DateInput, BooleanInput, ReferenceInput, SelectInput, NumberInput, required, minLength, maxLength} from 'react-admin';
import { Box } from '@material-ui/core';

import { useQuery, Loading, Error } from 'react-admin';

import { RaGrid } from 'ra-compact-ui';

import Front from './Front.js';

import Rear from './Rear.js';


const imageWidth = 200;
const imageHeight = Math.round(imageWidth / 0.471412);

export default ({updateForm, form}) => {
  const [selected, setSelected] = useState(null);
  const [selectedLesion, setSelectedLesion] = useState(null);

  const { data, loading, error } = useQuery({ 
    type: 'getList',
    resource: 'exams',
    payload: {
      pagination: { page: 1, perPage: 9999 },
      sort: {field: 'created_at', order: 'DESC'},
      filter: {}
    }
  });
  
  if (loading) return <Loading />;
  if (error) return  <Error/>;


  if (!data) return null;

  const exams = data.filter(({lesion}) => lesion.patient === form.patient); 
  console.log(selected, selectedLesion)

  function handleBodyClick(value) {
    updateForm('lesion_position', value);
    updateForm('lesion_id', null);
    setSelected(value);
    setSelectedLesion(null);
  }

  function handleLesionClick(value) {
    updateForm('lesion_id', value?.id);
    updateForm('lesion_position', null);
    setSelectedLesion(value);
    setSelected(null);

  }

  return (
    <>
    <Box aria-label="front_rear" display="flex" sx={{marginBottom: 50, marginLeft: 70, marginRight: 70, flexWrap: 'wrap', flexDirection: 'row', justifyContent: 'space-between' }}>
      <Front height={imageHeight} width={imageWidth} selected={selected} setSelected={handleBodyClick} selectedLesion={selectedLesion} setSelectedLesion={handleLesionClick} color="primary" exams={exams}/>
      <Rear height={imageHeight} width={imageWidth} selected={selected} setSelected={handleBodyClick} selectedLesion={selectedLesion} setSelectedLesion={handleLesionClick} color="primary" exams={exams}/>
    </Box>
    <Box aria-label="selected" display="flex" sx={{marginBottom: 50, marginLeft: 10, marginRight: 10, flexWrap: 'wrap', flexDirection: 'row', justifyContent: 'space-betweens' }}>
      <div style={{width: '100%'}} align="center">
        {selected && (<><h2>Creating new lesion on </h2><h2>{selected}</h2></>)}
        {selectedLesion && (<><h2>Selected existing lesion </h2><h2>{selectedLesion?.id} on {selectedLesion?.site}</h2></>)}
      </div>
    </Box>
    </>
  )
}