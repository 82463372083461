import React, { useState } from 'react'
import {
    Avatar,
    Box,
    Button,
    Link,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Tooltip,
    Grid,
    Item,
    CardMedia,
    Divider
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import {d_map} from './diagnosis_schema';

export default ({updateForm}) => {  
  const [diagnosis, setDiagnosis] = useState({one: null, two: null, three: null, ml_class: null, options: [], alternative: null});
 
  const classes = useStyles();

  function handleOptions(option) {
    var actual = diagnosis.options;
    console.log(actual)
    if (actual.includes(option)) actual = actual.filter(x => x!==option);
    else actual.push(option);
    console.log(actual)
    handle({...diagnosis, options: [...actual]});

  }  

  function handle(which) {
    setDiagnosis(which);
    updateForm('diagnosis', which)

  }  
  
  function getOptions() {
      return d_map.filter(x => x.name===diagnosis.one)[0].choices.filter(x => x.name===diagnosis.two)[0].choices.filter(x => x.name===diagnosis.three)[0].options;
  }

  function getAlternatives() {
    return d_map.filter(x => x.name===diagnosis.one)[0].choices.filter(x => x.name===diagnosis.two)[0].choices.filter(x => x.name===diagnosis.three)[0].alternatives;
}

  return (
    <>
      
      <Box display="flex" sx={{ height: '100%', flexDirection: 'column', justifyContent: 'space-between' }}>
          <Box key="diag">
    <Box key="one" display="flex" sx={{ marginBottom: 30, marginLeft: 150, marginRight: 150, justifyContent: 'space-between' }}>

    {d_map.map((item, index) => {
        return (
            <Button
                key={item.name}
                className={classes.link1}
                component={Link}
                size="small"
                color="primary"
                onClick={() => handle({...diagnosis, one: item.name, two: null, three: null, ml_class: null, options:[], alternative:null})}
            >
                <Box p={5} className={classes.linkContent} style={{
    display: 'flex',
    alignItems: 'center',
}}>
                {diagnosis.one===item.name ? <CheckIcon/> : <CheckBoxOutlineBlankIcon/>} <span style={{marginLeft: 10}}>{item.name}</span>
                </Box>
            </Button>
        )
    })}
    </Box>
    
    {diagnosis.one && (
    <>
    <Box key="two" display="flex" sx={{ marginBottom: 30, marginLeft: 50, marginRight: 50, justifyContent: 'space-between' }}>

    {d_map.filter(x => x.name===diagnosis.one)[0].choices.map((item2, index) => 

    <Button
        key={item2.name}
        className={classes.link2}
        component={Link}
        to="/images"
        size="small"
        color="primary"
        onClick={() => handle({...diagnosis, two: item2.name, three: null, ml_class: null, options:[], alternative:null})}

    >
        <Box p={3} className={classes.linkContent} style={{
    display: 'flex',
    alignItems: 'center',
}}>
        
        {diagnosis.two===item2.name ? <CheckIcon/> : <CheckBoxOutlineBlankIcon/>} <span style={{marginLeft: 10}}>{item2.name}</span>
        </Box>
    </Button>
        )}

    </Box>

    {diagnosis.two && (
    <>
    <Box key="three" display="flex" sx={{ marginBottom: 0, marginLeft: 10, marginRight: 10, flexWrap: 'wrap', justifyContent: 'space-between' }}>

    {d_map.filter(x => x.name===diagnosis.one)[0].choices.filter(x => x.name===diagnosis.two)[0].choices.map((item3, index) => 

    <Button
        key={item3.name}
        className={classes.link3}
        component={Link}
        to="/images"
        size="small"
        color="primary"
        onClick={() => handle({...diagnosis, three: item3.name, ml_class: item3.ml_class, options:[], alternative:null})}

    >
        <Box p={2} className={classes.linkContent} style={{
    display: 'flex',
    alignItems: 'center',
}}>
        
        {diagnosis.three===item3.name ? <CheckIcon/> : <CheckBoxOutlineBlankIcon/>} <span style={{marginLeft: 10}}>{item3.name}</span>
        </Box>
    </Button>
    )}

    </Box>


{diagnosis.three && (
<>

{ getOptions() && (
    <Box aria-label="options" display="flex" sx={{marginBottom: 0, marginLeft: 10, marginRight: 10, flexWrap: 'wrap', flexDirection: 'row', justifyContent: 'center' }}>
    {getOptions().map((option, index) => 
       <FormControlLabel key={index} control={
        <Checkbox 
            checked={diagnosis.options.includes(option)} 
            onChange={() => handleOptions(option)}
            name="options" />
        } 
        label={option} />
    )}
    </Box>
)}
{ getAlternatives() && (
    <Box aria-label="options" display="flex" sx={{marginBottom: 0, marginLeft: 10, marginRight: 10, flexWrap: 'wrap', flexDirection: 'row', justifyContent: 'center' }}>

    <RadioGroup row aria-labelledby="alternatives" size="small" name="alternative" value={diagnosis.alternative} 
        onChange={(event) => handle({...diagnosis, alternative: event.target.value})}>
    { getAlternatives().map((alternative, index) => 
        <FormControlLabel value={alternative} control={<Radio />} label={alternative}  />
    )}       
    </RadioGroup>
    </Box>
)}
</>
)}
</>
)}

</>  )}
</Box><br/>
<Box key="ref" display="flex" sx={{ fontStyle: 'italic' }}>
            Ref: Tognetti et al., Dermatol. Pract. Concept., 2018 Tognetti et. al, JEADV, 2018, Tognetti et al, Ach. Dermatol. Res., 2021, Roffman et al., Sci. Rep., 2018
        </Box>
</Box>
</>
      )
}


const useStyles = makeStyles(theme => ({
    avatar: {
        background: theme.palette.background.paper,
    },
    listItemText: {
        overflowY: 'hidden',
        height: '6em',
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
    },
    link1: {
        borderRadius: 4,
        backgroundColor: 'grey',
        '&:hover': {
            backgroundColor: '#00BCD4',
            color: '#FFF'
        }

    },
    link2: {
        borderRadius: 4,
        backgroundColor: 'green',
        '&:hover': {
            backgroundColor: '#00BCD4',
            color: '#FFF'
        }
    },
    link3: {
        borderRadius: 4,
        backgroundColor: 'blue',
        flex: '1 1 40%',
        marginBottom: 20,
        maxWidth: '200px',
        '&:hover': {
            backgroundColor: '#00BCD4',
            color: '#FFF'
        }
    },
    linkContent: {
        color: 'white',
    },
}));
