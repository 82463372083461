
const d_map = 
[
    {name:'Benigno', choices: [
         {name:'Melanocitario', choices: [
             {name:'Giunzionale', 
             ml_class: 'nev',
             options: [
                 'Spitz Reed',
                 'Blu', 
                 'Halo', 
                 'Regressione', 
                 'Atipie severe', 
                 'Balloon', 
                 'Congenito'
             ]},
             {name:'Dermico',
             ml_class: 'nev',
             options: [
                 'Spitz Reed',
                 'Blu', 
                 'Halo', 
                 'Regressione', 
                 'Atipie severe', 
                 'Balloon', 
                 'Congenito',
                 'Ota',
                 'Ito', 
                 'Chiazza Mongolica'
             ]},
             {name:'Composto', 
             ml_class: 'nev',
             options: [
                 'Spitz Reed',
                 'Blu', 
                 'Halo', 
                 'Regressione', 
                 'Atipie severe', 
                 'Balloon', 
                 'Congenito'
             ]},
         ]},
         {name:'Non Melanocitario', choices: [
             {name:'Lentigo solare',
             ml_class: 'bkl',
             },
             {name:'Cheratosi seborroica',
             ml_class: 'bkl',
            },
             {name:'Cheratosi attinica',
             ml_class: 'ak',
             options: [
                 'pigmentata', 
                 'ipercheratosica', 
                 'atrofica',
                 'bowenoide'
             ]},
             {name:'Cheratosi lichenoide',
             ml_class: 'bkl',
            },
             {name:'Tumore del connettivo',
             ml_class: 'unk',
             alternatives: [
                 'dermatofibroma',
                 'fibrocheratoma'
             ]},
             {name:'Tumore muscolare',
             ml_class: 'unk',
             alternatives: [
                 'leiomioma'
             ]},
             {name:'Tumore del tessuto adiposo',
             ml_class: 'unk',
            },
             {name:'Tumore del tessuto nervoso',
             ml_class: 'unk',
            },
             {name:'Cisti', 
             ml_class: 'unk',
             alternatives: [
                 'epidermica', 'miliare'
             ]},
             {name:'Nevo epidermico',
             ml_class: 'nev',
            },
             {name:'Lesione vascolare',
             ml_class: 'vasc',
             alternatives: [
                 'granuloma piogenico',
                 'emangioma',
                 'malformazione capillare',
                 'angiocheratoma',
                 'tumore glomico',
                 'linfangioma'
             ]},
             {name:'Tumore annessiale', 
             ml_class: 'unk',
             alternatives: [
                 'tricoblastoma',
                 'trichilemmoma',
                 'piloatricoma',
                 'iperplasia sebacea',
                 'adenoma sebaceo',
                 'nevo sebaceo',
                 'poroma'
             ]}

         ]},

         {name:'Non Tumore', choices: [
             {name:'Infiammatorio', 
             ml_class: 'unk',
             alternatives: [
                 'psoriasi',
                 'eczema',
                 'granuloma',
                 'acne',
                 'rosacea',
                 'panniculite',
                 'connettivopatia', 
                 'tossicodermia',
                 'istiocitosi',
                 'mastocitosi',
                 'orticaria',
             ]},
             {name:'Infezione', 
             ml_class: 'unk',
             alternatives: [
                 'batterica',
                 'virale',
                 'parassitaria', 
                 'micotica'
             ]},
             {name:'Malattia metabolica', 
             ml_class: 'unk',
             alternatives: [
                 'amiloidosi', 
                 'porfiria', 
                 'calcinosi', 
                 'mucinosi', 
             ]},
             {name:'Alterazione della pigmentazione', 
             ml_class: 'unk',
             alternatives: [
                 'lentigo solare',  
                 'melanosi', 
                 'vitiligine', 
                 'melasma', 
                 'chiazza caffè e latte', 
             ]},
             {name:'Sottobracci: connettivopatia', 
             ml_class: 'unk',
             alternatives: [
                 'lupus', 
                 'sclerodermia', 
                 'dermatomiosite', 
             ]},
             {name:'Malattia vascolare', 
             ml_class: 'unk',
             alternatives: [
                 'vasculite',
                 'porpora',
             ]},
             {name:'Alopecia', 
             ml_class: 'unk',
             alternatives: [
                 'areata',
                 'androgenetica',
             ]},
             
         ]},

    ]},
    {name:'Maligno', choices: [
         {name:'Melanocitario', choices: [
             {name:'Melanoma in situ', 
             ml_class: 'mel',
             alternatives: [ 
                 'lentigo maligna',
                 'superficial spreading',
                 'acrale',
                 'mucoso',
             ]},
             {name:'Melanoma invasivo', 
             ml_class: 'mel',
             alternatives: [ 
                 'lentigo maligna melanoma',
                 'superficial spreading',
                 'nodulare',
                 'acrale',
                 'mucoso',
             ]},
             {name:'Nevo blu maligno',
             ml_class: 'mel',
            },
             {name:'Metastasi',
             ml_class: 'mel',
            },

                         
         ]},
         {name:'Non Melanocitario', choices: [
             {name:'SCC', 
             ml_class: 'scc',
             alternatives: [ 
                 'in situ',
                 'invasivo',
             ]},           
             {name:'BCC', 
             ml_class: 'bcc',
             alternatives: [  
                 'superficiale',
                 'nodulare',
                 'infiltrante', 
                 'misto',
             ]},           
             {name:'Merkel cell carcinoma',
             ml_class: 'unk',
            },
             {name:'Linfoma cutaneo', 
             ml_class: 'unk',
             alternatives: [ 
                 'B',
                 'T',
                 'NK'
             ]},           
             {name:'Tumore di Kaposi',
             ml_class: 'unk',
            },
             {name:'Malattia di Paget',
             ml_class: 'unk',
            },
             {name:'Tumore annessiale maligno',
             ml_class: 'unk',
            },
             {name:'Tumore vascolare maligno', 
             ml_class: 'vasc',
             alternatives: [ 
                 'angiosarcoma'
             ]},           
             {name:'Tumore muscolare maligno',
             ml_class: 'unk',
            },
             {name:'Tumore maligno del tessuto connettivo', // (dermatofibrosarcoma fibroxantoma atipico)
             ml_class: 'unk',
            }, 
             {name:'Tumore maligno del tessuto adiposo',
             ml_class: 'unk',
            },
             {name:'Metastasi',
             ml_class: 'unk',
            },
             
         ]},
 
    ]},

];

export {d_map};